import { Formik } from "formik";
import { Heading } from "../../../../../atoms/Heading";
import style from "./index.module.scss";
import { rateNameData } from "../../../../../../utils/staticData";
import CustomButton from "../../../../../atoms/CustomButton";
import { getData, patchData, postData } from "../../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import * as Yup from "yup";
import MyDropdown from "../../../../../atoms/MyDropdown";
import MyInput from "../../../../../atoms/MyInput";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";

const AddRate = ({ type }) => {
  const navigate = useNavigate();
  const { id, date } = useParams();
  const [cookies] = useCookies(["t"]);
  const [editedData, setEditedData] = useState({});
  const [rateError, setRateError] = useState({
    isActive: null,
    data: null,
  });

  const initialValues = {
    rate_name: editedData?.rate_name || "",
    date: editedData?.rates?.[0]?.date || "",
    base_rate: editedData?.rates?.[0]?.base_rate || "",
  };
  const validationSchema = Yup.object().shape({
    rate_name: Yup.string().required("Rate Name is required"),
    date: Yup.string().required("Date is required"),
    base_rate: Yup.string().required("Base Rate is required"),
  });
  const getPriceDetailsById = async () => {
    try {
      const res = await getData({
        endpoint: "/rates/getRateById",
        params: {
          id: id,
          date: date,
        },
        token: cookies.t,
      });
      setEditedData(res?.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (id && date) {
      getPriceDetailsById();
    }
  }, [id]);

  const handleSubmit = async (values) => {
    const dataToSend = {
      rate_name: values.rate_name,
      rates: [
        {
          date: values.date,
          base_rate: values.base_rate,
        },
      ],
    };
    if (id) {
      const res = await patchData({
        endpoint: "rates/update",
        token: cookies.t,
        params: {
          id: id,
          date: values.date,
          base_rate: values.base_rate,
        },
      });
      if (res) {
        toast.success("Rate Details Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        navigate("/dashboard/setting/rate-management");
      }
    } else {
      try {
        const res = await postData({
          endpoint: "rates/create",
          data: dataToSend,
          token: cookies.t,
        });
        if (res) {
          toast.success("Rate Details Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/setting/rate-management");
        }
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  const checkRate = async (newVal) => {
    const response = await getData({
      endpoint: "rates/checkRate",
      params: {
        rate_name: newVal,
      },
      token: cookies.t,
    });
    if (response.data && response.data.status) {
      console.log("tyep :", type);

      if (type !== "edit")
        setRateError(() => ({
          isActive: "Rate Already Exists",
          data: response?.data?.data,
        }));
    } else {
      setRateError(() => ({
        isActive: "",
        data: "",
      }));
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        key={Math.random}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          resetForm,
          isSubmitting,
          setFieldTouched,
        }) => (
          <>
            <Breadcrumb
              labels={
                type === "edit"
                  ? ["Rate", "Update Rate"]
                  : ["Rate", "Create Rate"]
              }
              stepsToRemove={2}
            />
            <Heading className="addHeaderStyle">
              {" "}
              {type === "edit" ? "Update" : "Create"} Rate
            </Heading>
            {console.log("errors", errors)}
            <div
              className="d-flex flex-wrap w-100"
              style={{ columnGap: "1.36%", rowGap: "16px" }}
            >
              <MyDropdown
                placeholder="Rate Name"
                data={rateNameData}
                onChange={(option) => {
                  setFieldValue("rate_name", option);
                }}
                handleBlur={(e) => {
                  setFieldTouched("rate_name", true);
                  handleBlur(e);
                  checkRate(values.rate_name);
                }}
                selectedValue={values?.rate_name}
                menuOpen={true}
                name="rate_name"
                touched={touched.rate_name}
                errors={errors.rate_name || rateError.isActive}
                width="23.9%"
                isDisabled={type === "edit"}
              />
              <div style={{ position: "relative", width: "23.9%" }}>
                <DateSelector
                  placeholder="Select Date"
                  onChange={async (option) => {
                    setFieldValue("date", option);
                  }}
                  handleBlur={(e) => {
                    setFieldTouched("date", true);
                    handleBlur(e);
                  }}
                  selectedDate={values.date}
                  errors={errors.date}
                  touched={touched.date}
                />
              </div>
              <MyInput
                type="number"
                name="base_rate"
                customholder="Base Rate"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                InputHeight="small"
                width="23.9%"
              />
            </div>
            <div className={style.buttonWrap}>
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <div className={style.resetAdd}>
                <CustomButton
                  text="Reset"
                  type="btn-outline-primary"
                  size="btn-lg"
                  handleClick={() => resetForm()}
                />
                <button
                  className="btn-primary btn-lg"
                  type="submit"
                  disabled={
                    !isValid || !dirty || isSubmitting || rateError.isActive
                  }
                  style={{ width: "100%" }}
                  onClick={() => handleSubmit(values)}
                >
                  {id ? "Update" : "Create"}
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
export default AddRate;
