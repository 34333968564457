import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Lock from "../../icons/Lock";
import UpwardArrow from "../../icons/UpwardArrow";
import Bottomarrow from "../../icons/Bottomarrow";

const CustomNavLink = ({
  to,
  label,
  icon,
  isLocked,
  alternatePath,
  index,
  handleSideBar,
  isSideBarActive,
  setIsSideBarActive,
  children, // New prop for nested links
}) => {
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false); // State to manage submenu

  const isActive = () => {
    return to === "/dashboard"
      ? location.pathname === "/dashboard"
      : location.pathname.startsWith(to);
  };

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  // Check if any child link is active
  const isChildActive = () => {
    if (children) {
      return React.Children.toArray(children).some((child) => {
        return location.pathname.startsWith(child.props.to);
      });
    }
    return false;
  };

  // Determine if the current link or any of its children are active
  const linkIsActive = isActive();

  const childActive = isChildActive();

  const applyActiveClass = () => {
    if (isSideBarActive) {
      return linkIsActive;
    } else {
      return childActive || (linkIsActive && icon);
    }
  };

  return (
    <>
      <li key={index} className={`${applyActiveClass() && "active-side"}`}>
        <div onClick={handleSideBar} className="sidebar-link-container">
          <NavLink
            to={isLocked ? alternatePath : to}
            className={`${linkIsActive && "sidebar-active-link"} sidebar-link`}
            onClick={(e) => {
              if (children) {
                e.preventDefault(); // Prevents navigation if the link has children
                handleExpandClick();
                if (!isSideBarActive && setIsSideBarActive) {
                  debugger;
                  setIsSideBarActive(true);
                  handleExpandClick();
                }
              }
            }}
          >
            <div className="d-flex align-items-center">
              <i>{icon}</i>
              <span style={{ whiteSpace: "nowrap" }}>
                {isSideBarActive && label}
              </span>
            </div>
            {children && isSideBarActive && (
              <div onClick={handleExpandClick}>
                {isExpanded ? <UpwardArrow /> : <Bottomarrow />}
              </div>
            )}
            {isLocked && <Lock />}
          </NavLink>
        </div>
      </li>
      {isExpanded && isSideBarActive && <div className="mt-2">{children}</div>}
    </>
  );
};

export default CustomNavLink;
