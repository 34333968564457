import axios from "axios";
import * as pd_api from "pandadoc-node-client";

export const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "User-Role": "Admin",
  },
});

// export default instance;

export const getPandaData = async ({ endpoint, params, token }) => {
  try {
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `API-Key ${token}`,
        "Content-Type": "application/pdf",
      },
      responseType: "blob",
    });
    return response;
  } catch (error) {
    console.error("Error getting data:", error);
    throw error;
  }
};
export const getPandaApi = async ({ endpoint, params, token }) => {
  try {
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `API-Key ${token}`,
        "Content-Type": "application/pdf",
      },
    });
    return response?.data;
  } catch (error) {
    console.error("Error getting data:", error);
    throw error;
  }
};

const API_KEY = "0752d8cf5fef4dfefa1c9ed82eef75295fda72ec";
const configuration = pd_api.createConfiguration({
  authMethods: { apiKey: `API-Key ${API_KEY}` },
});
export const apiInstance = new pd_api.DocumentsApi(configuration);

export const postCreateDoc = async ({ endpoint, params, token, data }) => {
  try {
    const response = await axios.post(endpoint, data, {
      params,
      headers: {
        Authorization: `API-Key ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    console.error("Error getting data:", error);
    throw error;
  }
};
