import { memo, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getData } from "../../../../../services";
import NoContentCard from "../../../../atoms/NoContentCard";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import { useParams } from "react-router-dom";

const CounterPartyDue = () => {
  const [cookies] = useCookies(["t"]);
  const { id } = useParams();
  const [duesData, setDuesData] = useState([]);

  const getDueData = async (fundingData) => {
    console.log("fundingData :", fundingData);

    try {
      const res = await getData({
        endpoint: "funding/getFundingByClientId",
        params: {
          id: fundingData._id,
          status: "Funded",
          trans_type: "Client",
        },
        token: cookies.t,
      });
      setDuesData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      return res?.data;
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getCounterDataById();
        console.log("result :", result);

        if (result) {
          await getDueData(result);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Content Found"
          subtitle=""
          type="See Dues"
          classes="d-flex align-items-center"
        />
      </>
    );
  });
  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.client_name}</div>;
      },
    },
    {
      field: "obligor_name",
      headerName: "Obligor",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.obligor_name}</div>;
      },
    },
    {
      field: "supplier_name",
      headerName: "Supplier",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.supplier_name}</div>;
      },
    },
    {
      field: "cost_value",
      headerName: "Cost Value",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{parseFloat(params?.row?.cost_value)}</div>;
      },
    },
    {
      field: "face_value",
      headerName: "Face Value",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div>
            {parseFloat(params?.row?.face_value) +
              parseFloat(params?.row?.cost_value)}
          </div>
        );
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.currency}</div>;
      },
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.transaction_type}</div>;
      },
    },
  ];

  console.log("duesData", duesData);
  return (
    <>
      <CustomDatagrid
        rows={duesData}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {/* {duesData?.pageDetails?.count > 10 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={fundingData?.[0]?.pageDetails?.count}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )} */}
    </>
  );
};

export default CounterPartyDue;
