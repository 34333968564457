import React from "react";
import { useNavigate } from "react-router-dom";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../atoms/NoContentCard";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import CustomButton from "../../../atoms/CustomButton";
import moment from "moment/moment";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import MyDropdown from "../../../atoms/MyDropdown";
import { DateSelector } from "../../../atoms/MonthYearSelector";
import {
  counterPartyType,
  GICSectorData,
  statusData,
  transactionType,
} from "../../../../utils/staticData";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../services/downloadService";
import { useSelector } from "react-redux";
import { getData } from "../../../../services";

const ReportsBroker = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [counterpartyData, setCounterPartData] = useState([]);
  const [investorData, setInvestorData] = useState([]);
  const [formData, setFormData] = useState({
    fund: "",
    date: "",
  });
  const [filters, setFilters] = useState({});

  const handleConfirmSelection = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getAllFundingsByBrokers",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          status: ["Funded"],
          transactionType: filters?.["Transaction Type"],
          dueStartDate: filters?.startDate0,
          dueEndDate: filters?.endDate0,
          fundingStartDate: filters?.startDate1,
          fundingEndDate: filters?.endDate1,
          investorID: formData.fund,
          as_of_date: formData.date,
        },
      });
      setCounterPartData(res?.data);
    } catch (err) {
      console.log("errr", err);
    }
  };

  useEffect(() => {
    if (formData.fund || formData.date) {
      handleConfirmSelection();
    }
  }, [itemsPerPage, currentPage, searchData, filters]);

  const getInvestorData = async () => {
    try {
      const res = await getData({
        endpoint: "investor/getInvestorList",
        token: cookies.t,
      });
      setInvestorData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getInvestorData();
  }, []);

  const columns = [
    {
      field: "Obligor",
      headerName: "Obligor",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.obligor_name}</div>
        );
      },
    },
    {
      field: "Fund Date",
      headerName: "Fund Date",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {moment(params?.row?.createdAt).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      field: "Due Date",
      headerName: "Due Date",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {moment(params?.row?.due_date).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      field: "Currency",
      headerName: "Currency",
      flex: 1,
      renderCell: (params) => {
        return <div className={style.valueStyle}>{params?.row?.currency}</div>;
      },
    },
    {
      field: "Cost",
      headerName: "Cost",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.cost_value}</div>
        );
      },
    },
    {
      field: "Fund",
      headerName: "Fund",
      flex: 1,
      renderCell: (params) => {
        return <div className={style.valueStyle}>{params?.row?.funder}</div>;
      },
    },
    {
      field: "Date",
      headerName: "Date",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>
              {formData.date && moment(formData.date).format("YYYY-MM-DD")}
            </span>
          </>
        );
      },
    },
    {
      field: "Principle Remaining",
      headerName: "Principle Remaining",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.cost_value && params.row.paymentHistory && (
              <span>
                {params.row.cost_value -
                  params.row.paymentHistory.reduce((total, item) => {
                    if (item.paymentType === "principle") {
                      return total + item.paymentAmount;
                    }
                    return total;
                  }, 0)}
              </span>
            )}
          </>
        );
      },
    },
    {
      field: "Principle Paid",
      headerName: "Principle Paid",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.face_value && params.row.paymentHistory && (
              <span>
                {params.row.paymentHistory.reduce((total, item) => {
                  if (item.paymentType === "principle") {
                    return total + item.paymentAmount;
                  }
                  return total;
                }, 0)}
              </span>
            )}
          </>
        );
      },
    },
    {
      field: "Interest Paid",
      headerName: "Interest Paid",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.face_value && params.row.paymentHistory && (
              <span>
                {params.row.face_value -
                  params.row.paymentHistory.reduce((total, item) => {
                    if (item.paymentType === "Interest") {
                      return total + item.paymentAmount;
                    }
                    return total;
                  }, 0)}
              </span>
            )}
          </>
        );
      },
    },
    {
      field: "Interest Accured",
      headerName: "Interest Accured",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.cost_value && params.row.paymentHistory && (
              <span>
                {params.row.paymentHistory.reduce((total, item) => {
                  if (item.paymentType === "Interest") {
                    return total + item.paymentAmount;
                  }
                  return total;
                }, 0)}
              </span>
            )}
          </>
        );
      },
    },
    {
      field: "Broker Name",
      headerName: "Broker Name",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{}</span>
          </>
        );
      },
    },
    {
      field: "Broker Rate",
      headerName: "Broker Rate",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{}</span>
          </>
        );
      },
    },
    {
      field: "Fee Earnt",
      headerName: "Fee Earnt",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{}</span>
          </>
        );
      },
    },
    {
      field: "Fee Accrued",
      headerName: "Fee Accrued",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{}</span>
          </>
        );
      },
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Brokers created yet!"
          tag="Add Counterparties"
          type="Counterparty"
          classes="d-flex align-items-center"
        />
      </>
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "funding/exportBrokers",
      token: cookies.t,
      fileName: "Brokers",
      params: {
        search: searchData,
        status: ["Funded"],
        transactionType: filters?.["Transaction Type"],
        dueStartDate: filters?.startDate0,
        dueEndDate: filters?.endDate0,
        fundingStartDate: filters?.startDate1,
        fundingEndDate: filters?.endDate1,
        investorID: formData.fund,
        as_of_date: formData.date,
      },
    });

    if (res) {
      toast.success("Brokers Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className="mb-3">
        <Heading className="HeadingStyle">Brokers</Heading>
      </div>
      <div className="mb-4 d-flex gap-3">
        <div className="d-flex gap-3 w-100">
          <div
            style={{
              width: "30%",
              position: "relative",
            }}
          >
            <MyDropdown
              data={investorData}
              placeholder="Select Fund"
              onChange={(option) => {
                setFormData({
                  ...formData,
                  fund: option,
                });
              }}
              selectedValue={formData.fund}
            />
          </div>
          <div
            style={{
              width: "30%",
              position: "relative",
            }}
          >
            <DateSelector
              placeholder="As of Date"
              onChange={(option) => {
                setFormData({
                  ...formData,
                  date: option,
                });
              }}
              selectedDate={formData.date}
            />
          </div>
        </div>
        <div>
          <CustomButton
            text="Confirm Selection"
            type="btn-outline-primary"
            size="btn-lg"
            style={{ minWidth: "max-content" }}
            handleClick={() => handleConfirmSelection()}
          />
        </div>
      </div>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Brokers List</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            // filterData={filtersData}
            isDateRange
            dateRangeNames={["Due Date", "Funding Date"]}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>

      <CustomDatagrid
        rows={counterpartyData?.[0]?.fundingData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {counterpartyData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={counterpartyData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
    </>
  );
};

export default ReportsBroker;
