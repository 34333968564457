import { useNavigate } from "react-router-dom";
import CustomButton from "../../../atoms/CustomButton";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../services";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import Delete from "../../../icons/Delete";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../assets/styles/components/toast";
import Modal from "../../../atoms/modals/DeleteModal";
import Edit from "../../../icons/EditBlue";
import CustomMenu from "../../../molecules/CustomMenu";
import HorizontalMenu from "../../../icons/HorizontalMenu";
import moment from "moment";
import View from "../../../icons/View";
import { useSelector } from "react-redux";

const Tools = () => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Tools;
  const [documentsData, setDocumentsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });

  const GetDocumentsData = async () => {
    try {
      const res = await getData({
        endpoint: "/documents",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
        },
      });
      setDocumentsData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    GetDocumentsData();
  }, [currentPage, itemsPerPage, searchData]);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Documents created yet!"
          tag="Create Documents"
          type="Pricing"
          classes="d-flex align-items-center"
          {...(roleData && roleData?.["add/edit"]
            ? {
                handleClick: () => {
                  navigate("/dashboard/tools/documents");
                },
                subtitle:
                  "No Documents have been created yet. Click on Create Documents button to add new Document.",
              }
            : {})}
        />
      </>
    );
  });

  const menu = [
    {
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        navigate(`view/${selectedRow?._id}`);
      },
    },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          name: selectedRow.name,
          modalId: selectedRow._id,
        });
        setAnchorEl(null);
      },
    },
  ];

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "document_name",
      headerName: "Document Name",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.documentSubType}</div>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {moment(params.row?.createdAt).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      field: "external_entity_email",
      headerName: "External Email(sent to)",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {params?.row?.DocumentsDetails?.external_entity_email}
          </div>
        );
      },
    },
    {
      field: "external_entity_first_name",
      headerName: "External name (Sent to)",
      flex: 0.42,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {params?.row?.DocumentsDetails?.external_entity_first_name +
              " " +
              params?.row?.DocumentsDetails?.external_entity_last_name}
          </div>
        );
      },
    },
    {
      field: "external_entity",
      headerName: "External Entity",
      flex: 0.42,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {params?.row?.DocumentsDetails?.external_entity}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.4,
      renderCell: (params) => {
        return <div className={style.valueStyle}>{params.row?.status}</div>;
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.2,
      renderCell: (params, index) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={getMenuList()}
            />
          </>
        );
      },
    },
  ];
  const getMenuList = () => {
    if (roleData && roleData?.view && roleData?.delete) {
      return menu;
    } else if (roleData && roleData?.delete) {
      return [menu[1]];
    } else if (roleData && roleData?.view) {
      return [menu[0]];
    } else return [];
  };
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "documents/delete",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Investor Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetDocumentsData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  return (
    <>
      <div
        className={`${style.headingSearchWrap} d-flex w-100 justify-content-between`}
      >
        <div>
          <Heading className="HeadingStyle">Tools</Heading>
        </div>
        <div className="searchButton">
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />
          {/* <ButtonDropDown menu={ButtonMenu} buttonTitle="Add Tools" /> */}
          {roleData && roleData?.["add/edit"] && (
            <CustomButton
              text="Create Documents"
              type="btn-primary"
              handleClick={() => {
                navigate("/dashboard/tools/documents");
              }}
            />
          )}
        </div>
      </div>
      <CustomDatagrid
        rows={documentsData?.[0]?.documentData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {documentsData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={documentsData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Investor"
        name={modalStateDelete.name}
      />
    </>
  );
};
export default Tools;
