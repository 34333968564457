import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import "../Dropdown/styles.scss";
import Error from "../Error";
import InfoContainer from "../Info";
import {
  customDropdownStyles,
  filterDropdownStyles,
  errorStyles,
  multiSelectStyles,
} from "../../../assets/styles/components/dropdown";
import "./styles.scss";
import Info from "../../icons/Info";
import { padding } from "@mui/system";
import InfoInsurance from "../../icons/infoinsurance";

const tagsColors = {
  Supplier: "#C8E1FE",
  "Supplier Credit": "#C8E1FE",
  Obligor: "#FEE2C8",
  Client: "#EDC8FE",
};

const TagDropdown = ({
  id,
  multiSelect = false,
  selectedValue,
  onChange,
  classes,
  placeholder,
  data,
  format,
  isDisabled,
  type,
  isClearable,
  onClear,
  label,
  menuOpen,
  isError,
  handleBlur = () => {},
  touched,
  errors,
  info,
  tagSplit = false,
  setInsuranceItem = () => {},
  isInfoData = false,
}) => {
  const options = data || [];

  const [selectedOptions, setSelectedOptions] = useState(null);
  const [isFocused, setIsFocused] = useState(selectedValue ? true : false);

  useEffect(() => {
    if (multiSelect && selectedValue) {
      const initialSelectedOptions = options?.filter((option) =>
        selectedValue?.includes(option.value),
      );
      setSelectedOptions(initialSelectedOptions);
    } else if (!multiSelect && selectedValue != null) {
      const initialSelectedOption =
        options?.find((option) => option?.value === selectedValue) || null;
      setSelectedOptions(initialSelectedOption);
    } else {
      const defaultSelectedOptions = multiSelect ? [] : null;
      setSelectedOptions(defaultSelectedOptions);
    }

    if (selectedValue) setIsFocused(true);
    if (!selectedValue || selectedValue.length === 0) {
      setIsFocused(false);
    }
  }, [selectedValue, multiSelect, options]);

  const handleSelectChange = (selectedOptions) => {
    if (
      selectedOptions[0]?.value !== "Add new signer" &&
      selectedOptions[0]?.value !== "Add new broker" &&
      selectedOptions[0]?.value !== "Add new Insurance"
    ) {
      setSelectedOptions(selectedOptions);
    }

    if (multiSelect) {
      let selectedValues;
      if (format === "all") {
        selectedValues = selectedOptions?.map((option) => option);
      } else {
        selectedValues = selectedOptions?.map((option) => option?.value);
      }
      onChange(selectedValues);
    } else {
      if (!selectedOptions) {
        onClear && onClear();
      }
      if (format === "all") {
        onChange(selectedOptions);
      } else {
        onChange(selectedOptions ? selectedOptions?.value : null);
      }
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handlePlaceBlur = () => {
    if (!selectedOptions || selectedOptions.length === 0) {
      setIsFocused(false);
    }
  };

  const styles =
    touched && errors
      ? { ...customDropdownStyles, ...errorStyles }
      : multiSelect
      ? { ...customDropdownStyles, ...multiSelectStyles }
      : customDropdownStyles;

  // Custom format option label
  const formatOptionLabel = ({ label, tag, data }) => (
    <div className="custom-option">
      <span>{label}</span>
      {tag && (
        <span
          className="tag"
          style={{
            backgroundColor:
              tag.length > 7 && tag?.slice(0, 7) === "Primary"
                ? "#C8E1FE"
                : tag.length > 9 && tag?.slice(0, 9) === "Secondary"
                ? "#FEE2C8"
                : tagsColors?.[tag],
          }}
        >
          {tagSplit ? tag?.slice(0, 2) : tag}
          {isInfoData && (
            <div
              style={{
                marginLeft: "10px",
                marginBottom: "2.5px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setInsuranceItem({
                  isActive: true,
                  data: data,
                });
                console.log("varun");
              }}
            >
              <InfoInsurance width="16px" height="16px" />
            </div>
          )}
        </span>
      )}
    </div>
  );

  // Custom single value component to hide tag in selected value
  const SingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        {props.data.label}
      </components.SingleValue>
    );
  };

  // Custom multi value component to hide tag in selected values
  const MultiValue = (props) => {
    return (
      <components.MultiValue {...props}>
        <components.MultiValueLabel {...props}>
          {props.data.label}
        </components.MultiValueLabel>
      </components.MultiValue>
    );
  };

  return (
    <>
      <div style={{ position: "relative" }} className="w-100">
        <Select
          className={`basic-single ${classes}`}
          classNamePrefix="select"
          options={options}
          value={selectedOptions}
          isMulti={multiSelect}
          onChange={handleSelectChange}
          styles={
            type === "filter"
              ? filterDropdownStyles
              : type === "dashboard"
              ? customDropdownStyles
              : styles
          }
          key={id}
          placeholder=""
          isDisabled={isDisabled}
          isClearable={isClearable}
          onFocus={handleFocus}
          onBlur={(event) => {
            handlePlaceBlur();
            handleBlur(event);
          }}
          formatOptionLabel={formatOptionLabel} // Add this line
          components={{ SingleValue, MultiValue }} // Add this line
        />
        <label
          className={`input-placeholder-small ${
            isFocused || (selectedOptions && selectedOptions.length > 0)
              ? "active"
              : ""
          }`}
        >
          {placeholder}
        </label>
        {touched && errors ? <Error error={errors} type="small" /> : null}
        {info && !errors ? <InfoContainer info={info} /> : null}
      </div>
    </>
  );
};

export default TagDropdown;
