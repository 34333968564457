import TextInput from '../TextInput';

const MyInput = ({ width = '100%', className = '',info, ...props }) => {
  return (
    <div style={{ width: width }} className={className}>
      <TextInput info={info}{...props} />
    </div>
  );
};
export default MyInput;
