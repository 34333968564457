import { memo, useCallback, useEffect, useState } from "react";
import { Heading } from "../../../../atoms/Heading";
import Search from "../../../../atoms/Search";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import style from "./index.module.scss";
import NoContentCard from "../../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../../services";
import View from "../../../../icons/View";
import EditBlue from "../../../../icons/EditBlue";
import CustomButton from "../../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Pagination from "../../../../atoms/Pagination";
import Delete from "../../../../icons/Delete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import Modal from "../../../../atoms/modals/DeleteModal";
import Edit from "../../../../icons/EditBlue";
import CustomMenu from "../../../../molecules/CustomMenu";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
import { Rate_TypeData } from "../../../../../utils/staticData";
import EyeIcon from "../../../../icons/eye.icon";
import Upload from "../../../../icons/Upload";
import { downloadReport } from "../../../../../services/downloadService";
import moment from "moment";
import { useSelector } from "react-redux";

const RateManagement = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t"]);
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Rates;
  const [priceData, setPriceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    month: "",
    isModalOpen: false, // Set initial state to false
  });

  const menu = [
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(`edit/${selectedRow?._id}/${selectedRow.rates.date}`);
      },
    },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          date: selectedRow.rates.date,
          name: selectedRow.rate_name,
          modalId: selectedRow._id,
        });
        setAnchorEl(null);
      },
    },
  ];

  const GetPriceData = async () => {
    try {
      const res = await getData({
        endpoint: "/rates",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
        },
      });
      setPriceData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    GetPriceData();
  }, [itemsPerPage, currentPage, searchData]);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "rate_name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle} title={params?.row?.rate_name}>
            {params?.row?.rate_name}
          </div>
        );
      },
    },
    {
      field: "month_data",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            className={style.valueStyle}
            title={moment(params.row?.rates.date).format("YYYY-MM-DD")}
          >
            {moment(params.row?.rates?.date).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      field: "base_rate_data",
      headerName: "Base Rate",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            className={style.valueStyle}
            title={params?.row?.rates?.base_rate}
          >
            {params?.row?.rates?.base_rate}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.updatedAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.6,
      renderCell: (params, index) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={getMenuList()}
            />
          </>
        );
      },
    },
  ];
  const getMenuList = () => {
    if (roleData && roleData?.["add/edit"] && roleData?.delete) {
      return menu;
    } else if (roleData && roleData?.delete) {
      return [menu[1]];
    } else if (roleData && roleData?.["add/edit"]) {
      return [menu[0]];
    } else return [];
  };

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Rate created yet!"
          tag="Create Rate"
          type="Pricing"
          classes="d-flex align-items-center"
          {...(roleData && roleData?.["add/edit"]
            ? {
                handleClick: () => {
                  navigate("/dashboard/setting/rate-management/add");
                },
                subtitle:
                  "Currently no rate created yet click on a add rate button to create rate",
              }
            : {})}
        />
      </>
    );
  });
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "rates/delete",
        token: cookies.t,
        params: {
          id: modalStateDelete?.modalId,
          date: modalStateDelete?.date,
        },
      });
      if (res) {
        toast.success("Rate Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetPriceData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  function generateRandomString(length) {
    return Math.random()
      .toString(36)
      .substring(2, 2 + length);
  }

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Rates</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
              setCurrentPage(1);
            }}
          />

          {roleData && roleData?.["add/edit"] && (
            <CustomButton
              text="Create Rate"
              type="btn-primary"
              handleClick={() => {
                navigate("/dashboard/setting/rate-management/add");
              }}
            />
          )}
        </div>
      </div>
      <CustomDatagrid
        rows={priceData?.[0]?.roleData || []}
        getRowId={(row) => generateRandomString(10)}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {priceData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={priceData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Rate"
        name={modalStateDelete.name}
      />
    </>
  );
};
export default RateManagement;
