import React from "react";
import { useNavigate } from "react-router-dom";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../atoms/NoContentCard";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import CustomButton from "../../../atoms/CustomButton";
import moment from "moment/moment";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import {
  counterPartyType,
  GICSectorData,
  statusData,
  transactionType,
} from "../../../../utils/staticData";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../services/downloadService";
import { useSelector } from "react-redux";
import { getData } from "../../../../services";
import { convenantFrequency } from "../../../../helpers/staticData";

const ReportsCovenants = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [counterpartyData, setCounterPartData] = useState([]);
  const [covenantData, setCovenantData] = useState([]);
  const [filters, setFilters] = useState({});

  const getCovenantData = async () => {
    try {
      const res = await getData({
        endpoint: "/covenant",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          frequency: filters?.["Frequency"],
        },
      });
      setCovenantData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getCovenantData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  const columns = [
    {
      field: "Client",
      headerName: "Client",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.client_name}</div>
        );
      },
    },
    {
      field: "Requirenment",
      headerName: "Requirenment",
      flex: 1,
      renderCell: (params) => {
        return <div className={style.valueStyle}>{params?.row?.name}</div>;
      },
    },
    {
      field: "Requirenment(Detail)",
      headerName: "Requirenment(Detail)",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {params?.row?.long_description}
          </div>
        );
      },
    },
    {
      field: "Frequency",
      headerName: "Frequency",
      flex: 1,
      renderCell: (params) => {
        return <div className={style.valueStyle}>{params?.row?.frequency}</div>;
      },
    },
    {
      field: "Shift",
      headerName: "Shift",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.monthly_shift}</div>
        );
      },
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Covenants created yet!"
          tag="Add Counterparties"
          type="Counterparty"
          classes="d-flex align-items-center"
        />
      </>
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const filterData = {
    Frequency: convenantFrequency,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "covenant/export",
      token: cookies.t,
      fileName: "Covenant",
      params: {
        search: searchData,
        frequency: filters?.["Frequency"],
      },
    });

    if (res) {
      toast.success("Covenants Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Covenants</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            filterData={filterData}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>

      <CustomDatagrid
        rows={covenantData?.[0]?.data || []}
        getRowId={(row) => row?.unique_row_id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {covenantData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={covenantData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
    </>
  );
};

export default ReportsCovenants;
