import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getData } from "../../../../../services";
import { useCookies } from "react-cookie";
import { Heading } from "../../../../atoms/Heading";
import style from "./index.module.scss";

const ToolsView = () => {
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [documentData, setDocumentData] = useState({});
  const [pandaDocs, setPandaDocs] = useState();

  const getToolsView = async () => {
    try {
      const res = await getData({
        endpoint: "documents/getDocumentsById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setDocumentData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getPandaDocuments = async () => {
    try {
      const res = await getData({
        endpoint: "documents/download/documents",
        params: {
          id: documentData?.pandaDocs,
        },
        token: cookies.t,
      });
      setPandaDocs(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (documentData?.pandaDocs) {
      getPandaDocuments();
    }
  }, [documentData?.pandaDocs]);

  useEffect(() => {
    if (id) {
      getToolsView();
    }
  }, [id]);

  return (
    <>
      <Heading className="HeadingStyle">
        {documentData?.documentSubType}
      </Heading>
      {pandaDocs ? (
        <div className={style.pdfSizeWrap}>
          <iframe
            src={pandaDocs}
            style={{ width: "100%", height: "100%", border: "none" }}
            title="PDF Preview"
          />
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};
export default ToolsView;
