import React from "react";
import "./style.scss";
import RoundCheck from "../../icons/roundCheck";
const PasswordStrengthIndicator = ({ password, isPasswordValid, touched }) => (
  <div
    className={`password_strength ${
      !isPasswordValid && touched ? "conditions_not_met" : ""
    }`}
  >
    <p className="condition_heading">
      Your new password must follow these rules:
    </p>
    <Condition
      met={password.length >= 8}
      description="Having at least 8 Characters"
    />
    <Condition
      met={/[0-9]/.test(password)}
      description="Includes at least one dight (0-9)"
    />
    <Condition
      met={/[a-z]/.test(password)}
      description="Includes at least one lowercase letter (a-z)"
    />
    <Condition
      met={/[A-Z]/.test(password)}
      description="Includes at least one uppercase letter (A-Z)"
    />
    <Condition
      met={/[!@#$%^&*]/.test(password)}
      description="Includes at least one special character (e.g. !,@,#,$,%,&*)"
    />
  </div>
);

const Condition = ({ met, description }) => (
  <div className="condition_wrapper">
    <div className="mr-1">
      {met ? <RoundCheck checked={met} /> : <RoundCheck />}
    </div>
    <span className="condition_description">{description}</span>
  </div>
);

export default PasswordStrengthIndicator;
