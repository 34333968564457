import { Box, Tab, Tabs, Badge, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";

const _sx = {
  "& .Mui-selected": {
    fontWeight: "600",
    color: "#146EF5 !important",
    backgroundColor: "#fff !important",
  },
  "& button": {
    textTransform: "capitalize !important",
    paddingTop: "12px",
    fontWeight: "500",
    fontFamily: "Instrument Sans !important",
  },
  "& button:active, & .Mui-selected:active, & .Mui-selected:focus": {
    border: "none !important",
    outline: "none !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#146EF5",
  },
  "& .MuiBadge-root": {
    marginLeft: "15px",
    marginRight: "9px",
  },
};

const TabStrip = ({
  activeTab,
  onTabChange,
  data,
  allowedTabId,
  areTabsDisabled,
  margin,
}) => {
  const tabStripRef = useRef();

  const [width, setWidth] = useState(1100); // Track width
  const [visibleTabs, setVisibleTabs] = useState([]); // Tabs visible directly
  const [overflowTabs, setOverflowTabs] = useState([]); // Tabs in "More" dropdown
  const [anchorEl, setAnchorEl] = useState(null); // Anchor for "More" dropdown

  // Visible count settings based on screen size
  const visibleCount = useMemo(() => ({
    xs: 4,
    s: 6,
    m: 8,
    l: 9,
    xl: 10,
    xxl: 12,
  }), []);

  // Helper to get visible tab count based on width
  const getVisibleCount = useCallback(() => {
    if (width <= 600) return visibleCount.xs;
    if (width <= 960) return visibleCount.s;
    if (width <= 1400) return visibleCount.m;
    if (width <= 1600) return visibleCount.l;
    if (width <= 1900) return visibleCount.xl;
    return visibleCount.xxl;
  }, [width, visibleCount]);

  // Resize handler to track element width
  const handleResize = useCallback((entries) => {
    if (entries[0]) {
      setWidth(entries[0].contentRect.width);
    }
  }, []);

  // Calculate how many tabs to show and which ones overflow
  const handleCount = useCallback(() => {
    const visibleCount = getVisibleCount();
    if (data.length > visibleCount) {
      setVisibleTabs(data.slice(0, visibleCount));
      setOverflowTabs(data.slice(visibleCount));
    } else {
      setVisibleTabs(data);
      setOverflowTabs([]);
    }
  }, [data, getVisibleCount]);

  // Open/Close the "More" dropdown
  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  // Handle tab change from "More" dropdown
  const handleTabChangeFromMore = (label) => {
    onTabChange(null, label);  // Update the active tab
    handleMoreClose();         // Close the "More" menu
  };

  // Observe resizing
  useEffect(() => {
    const observer = new ResizeObserver(handleResize);
    if (tabStripRef.current) observer.observe(tabStripRef.current);

    return () => {
      if (tabStripRef.current) observer.unobserve(tabStripRef.current);
    };
  }, [handleResize]);

  // Recalculate tabs on data or width change
  useEffect(() => {
    handleCount();
  }, [data, width, handleCount]);

  return (
    <div id="tabstrip" ref={tabStripRef}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginX: margin,
          overflowX: "hidden", // Disable scroll
        }}
      >
        <Tabs value={activeTab} onChange={onTabChange} sx={_sx}>
          {visibleTabs.map((item) => {
            const isTabDisabled =
              areTabsDisabled &&
              allowedTabId !== undefined &&
              item.id !== allowedTabId;
            const isSelected = activeTab === item?.label;

            return (
              <Tab
                key={item?.id}
                value={item?.label}
                disabled={isTabDisabled}
                label={
                  <Box display="flex" alignItems="center">
                    <span style={{ color: isSelected ? "#146EF5" : "#1B242C" }}>
                      {item?.label}
                    </span>
                    {item.badge !== undefined && item.badge !== null && (
                      <Badge
                        showZero
                        badgeContent={item.badge}
                        sx={{
                          "& .MuiBadge-badge": {
                            backgroundColor: isSelected ? "#146EF5" : "#CFD6DD",
                            color: isSelected ? "white" : "black",
                          },
                        }}
                      />
                    )}
                  </Box>
                }
              />
            );
          })}

          {overflowTabs.length > 0 && (
            <Tab label="More" onClick={handleMoreClick} />
          )}
        </Tabs>
      </Box>

      {/* "More" dropdown */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMoreClose}
        PaperProps={{
            sx: {
              border: "1px solid #DEE3E7",
              boxShadow: "none",
              minWidth: "200px",
              marginTop: "8px",
              marginRight: "50px",
              borderRadius: "10px",
              padding: 0,
              "& .MuiList-root": {
                padding: "0px !important",
              },
            },
          }}
      >
        {overflowTabs.map((item) => (
          <MenuItem
            key={item?.id}
            onClick={() => handleTabChangeFromMore(item.label)}
            sx={{
                "&:hover": {
                  backgroundColor: "#F5F7F9", // Change to desired hover background color
                },
                color: "#1B242C",
                fontSize: "14px",
                fontWeight: "500",
                height: "44px",
              }}
          >
            {item?.label}
            {item.badge !== undefined && item.badge !== null && (
              <Badge
                badgeContent={item.badge}
                sx={{
                  marginLeft: "10px",
                  "& .MuiBadge-badge": {
                    backgroundColor: "#CFD6DD",
                    color: "black",
                  },
                }}
              />
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default TabStrip;
