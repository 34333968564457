import React, { useEffect, useState } from "react";
import { Field, FieldArray, Formik } from "formik";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import { Heading } from "../../../../../atoms/Heading";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import MyDropdown from "../../../../../atoms/MyDropdown";
import TextInput from "../../../../../atoms/TextInput";
import MyTextArea from "../../../../../atoms/MyTextArea";
import CustomButton from "../../../../../atoms/CustomButton";
import * as Yup from "yup";
import CounterDropDown from "../../../../../molecules/CounterDataDropdown";
import {
  convenantFrequency,
  monthly_shift,
  reportingDays,
  convenantNames,
} from "../../../../../../helpers/staticData";
import { postData, patchData, getData } from "../../../../../../services";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/components/toast";
import "./styles.scss";

const AddCovenant = ({ type }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    if (type === "edit") {
      (async () => {
        const res = await getData({
          endpoint: "/covenant/getCovenantById",
          params: { id: id },
          token: cookies.t,
        });
        setInitialData(res.data);
      })();
    }
  }, [type]);

  const initialValues = {
    client_id: initialData?.client_id || "",
    covenant_details: initialData?.covenant_details || [
      {
        name: "",
        long_description: "",
        frequency: "",
        reporting_days: "",
        monthly_shift: "",
        other_notes: "",
      },
    ],
  };

  const validationSchema = Yup.object().shape({
    client_id: Yup.string().required("Client Name is required"),
    covenant_details: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Covenant name is required"),
        long_description: Yup.string().required("Description is required"),
        frequency: Yup.string().required("Frequency is required"),
        reporting_days: Yup.string().required("Reporting Days are required"),
        monthly_shift: Yup.string().required("Monthly Shift is required"),
      }),
    ),
  });

  const handleSubmit = async (values) => {
    if (id) {
      const res = await patchData({
        endpoint: "covenant/updateCovenant",
        token: cookies.t,
        data: values,
        params: {
          id: id,
        },
      });
      if (res) {
        toast.success("Covenant Details Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        navigate("/dashboard/setting/covenant");
      }
    } else {
      try {
        const res = await postData({
          endpoint: "covenant/create",
          data: values,
          token: cookies.t,
        });
        if (res) {
          toast.success("Covenant Details Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/setting/covenant");
        }
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          resetForm,
          isSubmitting,
        }) => (
          <>
            <Breadcrumb
              labels={
                type === "edit"
                  ? ["Covenant", "Update Covenant"]
                  : ["Covenant", "Create Covenant"]
              }
              excludeLastPath={type === "edit"}
            />
            <Heading className="addHeaderStyle">
              {id ? "Update" : "Create"} Covenant
            </Heading>

            <div className="">
              {/* Client ID field (outside FieldArray) */}
              <div className="col-3 mt-3">
                <CounterDropDown
                  placeholder="Client Name"
                  onChange={(option) => {
                    setFieldValue("client_name", option.label);
                    setFieldValue("client_id", option?.value);
                  }}
                  selectedValue={values?.client_id}
                  format="all"
                  isError={errors.client_id}
                  touched={touched.client_id}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("client_id", true);
                  }}
                  errors={errors.client_id}
                />
              </div>

              {/* FieldArray for covenant_details */}
              <FieldArray
                name="covenant_details"
                render={(arrayHelpers) => (
                  <>
                    {values.covenant_details.map((detail, index) => (
                      <>
                        <div className="d-flex justify-content-between mt-4">
                          <h5 className="fw-semi mb-0">Covenant {index + 1}</h5>

                          <div className="d-flex gap-3">
                            {values.covenant_details?.length === index + 1 && (
                              <button
                                className="btn btn-primary px-4"
                                onClick={() =>
                                  arrayHelpers.push({
                                    name: "",
                                    long_description: "",
                                    frequency: "",
                                    reporting_days: "",
                                    monthly_shift: "",
                                    other_notes: "",
                                  })
                                }
                              >
                                Create More
                              </button>
                            )}

                            <button
                              onClick={() => arrayHelpers.remove(index)} // Remove the current field set
                              className="btn btn-outline-dark px-4 "
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                        <div key={index} className="row g-3 mt-2">
                          <div className="col-3 mt-0">
                            <TextInput
                              type="text"
                              name={`covenant_details[${index}].name`}
                              customholder="Name"
                              onBlur={handleBlur}
                              InputHeight="small"
                              info="Short Description of Covenant"
                              isError={errors?.covenant_details?.[index]?.name}
                              touched={touched?.covenant_details?.[index]?.name}
                            />
                          </div>

                          <div className="col-6 mt-0">
                            <MyTextArea
                              as="textarea"
                              customholder="Description"
                              name={`covenant_details[${index}].long_description`}
                              width="w-100"
                              rows={1}
                              info="Long Description of Covenant"
                              isError={
                                errors?.covenant_details?.[index]
                                  ?.long_description
                              }
                              touched={
                                touched?.covenant_details?.[index]
                                  ?.long_description
                              }
                            />
                          </div>
                        </div>

                        <div className="row g-3 mt-3">
                          <div className="col-3 mt-3">
                            <MyDropdown
                              placeholder="Frequency"
                              data={convenantFrequency}
                              onChange={(option) => {
                                setFieldValue(
                                  `covenant_details[${index}].frequency`,
                                  option.label,
                                );
                              }}
                              selectedValue={
                                values?.covenant_details?.[index]?.frequency
                              }
                              format="all"
                              isError={
                                errors?.covenant_details?.[index]?.frequency
                              }
                              touched={
                                touched?.covenant_details?.[index]?.frequency
                              }
                              handleBlur={handleBlur}
                            />
                          </div>

                          <div className="col-3 mt-3">
                            <MyDropdown
                              placeholder="Monthly Shift"
                              data={monthly_shift}
                              onChange={(option) => {
                                setFieldValue(
                                  `covenant_details[${index}].monthly_shift`,
                                  option,
                                );
                              }}
                              selectedValue={
                                values?.covenant_details?.[index]?.monthly_shift
                              }
                              isError={
                                errors?.covenant_details?.[index]?.monthly_shift
                              }
                              touched={
                                touched?.covenant_details?.[index]
                                  ?.monthly_shift
                              }
                              handleBlur={handleBlur}
                              info={
                                "For quarterly max shift is 2 for annual Max shift is 11"
                              }
                            />
                          </div>

                          <div className="col-3 mt-3">
                            <MyDropdown
                              placeholder="Reporting Days"
                              data={reportingDays}
                              onChange={(option) => {
                                setFieldValue(
                                  `covenant_details[${index}].reporting_days`,
                                  option,
                                );
                              }}
                              selectedValue={
                                values?.covenant_details?.[index]
                                  ?.reporting_days
                              }
                              isError={
                                errors?.covenant_details?.[index]
                                  ?.reporting_days
                              }
                              touched={
                                touched?.covenant_details?.[index]
                                  ?.reporting_days
                              }
                              handleBlur={handleBlur}
                              info={
                                "If reporting days are N/A. Please select 0."
                              }
                            />
                          </div>

                          <div className="col-3 mt-3">
                            <MyTextArea
                              as="textarea"
                              customholder="Other Notes"
                              name={`covenant_details[${index}].other_notes`}
                              width="w-100"
                            />
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                )}
              />

              <div className="buttonWrap mt-5">
                <div>
                  <CustomButton
                    text="Back"
                    size="btn-lg"
                    type="btn-outline-dark"
                    handleClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>
                <div className="resetAdd">
                  <CustomButton
                    text="Reset"
                    type="btn-outline-primary"
                    size="btn-lg"
                    handleClick={() => resetForm()}
                  />
                  <button
                    className="btn-primary btn-lg"
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                    style={{ width: "100%" }}
                    onClick={() => handleSubmit(values)}
                  >
                    {id ? "Update" : "Submit"}
                    {isSubmitting && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default AddCovenant;
