import React, { useState } from "react";
import PlusIcon from "../../../../../icons/PlusIcon";
import MinusIcon from "../../../../../icons/minusIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { postFormData } from "../../../../../../services";
import { Obj2FormImage } from "../../../../../../helpers/capatalizeLetters";
import { useCookies } from "react-cookie";
import { border } from "@mui/system";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/components/toast";

const FileUpload = () => {
  const { date, id } = useParams();
  const location = useLocation(); // Access location object
  const { name } = location.state || {}; // Access the state
  const [cookie] = useCookies(["t"]);
  const navigate = useNavigate();

  const [files, setFiles] = useState({
    financialAnalysis: [null],
    capIQRiskGauge: [null],
    bankStatement: [null],
    investmentMemo: [null],
    summaryInvestmentMemo: [null],
  });

  // Handle file selection
  const handleFileChange = (e, fileType, index) => {
    const newFiles = [...files[fileType]];
    newFiles[index] = e.target.files[0]; // Update the specific file in the array
    setFiles({
      ...files,
      [fileType]: newFiles,
    });
  };

  // Add more file inputs
  const addFileInput = (fileType) => {
    setFiles({
      ...files,
      [fileType]: [...files[fileType], null], // Add a new null entry to allow for a new file input
    });
  };

  // Remove file input
  const removeFileInput = (fileType, index) => {
    const newFiles = [...files[fileType]];
    if (index > 0) {
      // Allow removal only if it's not the first input
      newFiles.splice(index, 1); // Remove the file input at the specified index
      setFiles({
        ...files,
        [fileType]: newFiles,
      });
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const formData = new FormData();
  //   Object.keys(files).forEach((key) => {
  //     files[key].forEach((file, index) => {
  //       if (file) {
  //         formData.append(`${key}[${index}]`, file); // Append each file separately
  //       }
  //     });
  //   });

  // API call (example)
  // const handleSubmit = async (values) => {
  //   const formData = new FormData();
  //   console.log("Uploaded file:", files);

  //   formData.append(
  //     "files",
  //     files.financialAnalysis[0],
  //     files.financialAnalysis[0]?.name,
  //   );
  //   try {
  //     const res = await postFormData({
  //       endpoint: "funding/checkingExcel",
  //       token: cookie.t,
  //       data: Obj2FormImage(formData),
  //     });
  //     // if (res) {
  //     //   toast.success("Funding Created Successfully", {
  //     //     duration: 1000,
  //     //     style: confimationStyles,
  //     //   });
  //     // }
  //     // navigate("/dashboard/funding");
  //   } catch (err) {
  //     console.log("err", err);
  //   }
  // };
  // };

  const handleSubmit = async (values) => {
    const formData = new FormData();

    files.financialAnalysis?.forEach((file) => {
      formData.append("financialAnalysis", file);
    });
    files.capIQRiskGauge?.forEach((file) => {
      formData.append("capIQRiskGauge", file);
    });
    files.bankStatement?.forEach((file) => {
      formData.append("bankStatement", file);
    });
    files.investmentMemo?.forEach((file) => {
      formData.append("investmentMemo", file);
    });
    files.summaryInvestmentMemo?.forEach((file) => {
      formData.append("summaryInvestmentMemo", file);
    });

    try {
      const res = await postFormData({
        endpoint: "analysis/createAnalysis",
        token: cookie.t,
        params: {
          client_id: id,
          client_name: name,
          selected_date: date,
        },
        data: formData,
      });
      if (res) {
        toast.success("Files Uploaded Succesfully", {
          duration: 1000,
          style: confimationStyles,
        });
        navigate("/dashboard/monitoring/links/analysis");
      }
    } catch (err) {
      toast.error("Error Occured While Uploading Files", {
        duration: 1000,
        style: errorStyles,
      });
      console.log("err", err);
    }
  };

  return (
    <>
      <form style={styles.formContainer}>
        <div>
          <h6 className="fw-bold">
            Uploading files for : <b>{name}</b>&nbsp;&nbsp;&nbsp;Dated :{" "}
            <b>{date}</b>{" "}
          </h6>
        </div>

        <FileInputList
          label="Financial Analysis"
          fileType="financialAnalysis"
          files={files.financialAnalysis}
          onFileChange={handleFileChange}
          addFileInput={addFileInput}
          removeFileInput={removeFileInput}
        />
        <FileInputList
          label="CapIQ Risk Gauge"
          fileType="capIQRiskGauge"
          files={files.capIQRiskGauge}
          onFileChange={handleFileChange}
          addFileInput={addFileInput}
          removeFileInput={removeFileInput}
        />
        <FileInputList
          label="Bank Statement Analysis"
          fileType="bankStatement"
          files={files.bankStatement}
          onFileChange={handleFileChange}
          addFileInput={addFileInput}
          removeFileInput={removeFileInput}
        />
        <FileInputList
          label="Investment Memorandum"
          fileType="investmentMemo"
          files={files.investmentMemo}
          onFileChange={handleFileChange}
          addFileInput={addFileInput}
          removeFileInput={removeFileInput}
        />
        <FileInputList
          label="Summary Investment Memorandum"
          fileType="summaryInvestmentMemo"
          files={files.summaryInvestmentMemo}
          onFileChange={handleFileChange}
          addFileInput={addFileInput}
          removeFileInput={removeFileInput}
        />

        {/* <button
          type="submit"
          style={styles.submitButton}
          onClick={() => handleSubmit()}
        >
          Save
        </button> */}
      </form>
      <button
        type="submit"
        style={styles.submitButton}
        onClick={() => handleSubmit()}
      >
        Save
      </button>
    </>
  );
};

// Component for rendering a list of file inputs and adding/removing with plus/minus icons
const FileInputList = ({
  label,
  fileType,
  files,
  onFileChange,
  addFileInput,
  removeFileInput,
}) => (
  <div style={styles.fileInputContainer}>
    <label style={styles.label}>{label}</label>
    {files.map((file, index) => (
      <div key={index} style={styles.fileRow}>
        <input
          type="file"
          onChange={(e) => onFileChange(e, fileType, index)}
          style={styles.fileInput}
        />
        {/* {file && <span style={styles.fileName}>{file.name}</span>}{" "} */}
        {/* Display file name if chosen */}
        <div style={styles.iconContainer}>
          {index > 0 && ( // Show remove button only if it's not the first input
            <button
              type="button"
              style={styles.iconButton}
              onClick={() => removeFileInput(fileType, index)}
            >
              <MinusIcon />
            </button>
          )}
          {index === files.length - 1 && ( // Show add button only on the last input
            <button
              type="button"
              style={styles.iconButton}
              onClick={() => addFileInput(fileType)}
            >
              <PlusIcon />
            </button>
          )}
        </div>
      </div>
    ))}
  </div>
);

const styles = {
  formContainer: {
    width: "60%",
    fontFamily: "Arial, sans-serif",
  },
  fileInputContainer: {
    marginBottom: "20px",
    padding: "10px",
    // backgroundColor: "#f0f0f0",
    border: "1px solid #f0f0f0",
    borderRadius: "4px",
  },
  fileRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", // Ensures that the file input and buttons are spread out
    marginBottom: "10px",
  },
  fileInput: {
    flexGrow: 1, // Takes up remaining space
    marginRight: "10px",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    display: "block",
    marginBottom: "5px",
  },
  iconButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: "0",
  },
  submitButton: {
    backgroundColor: "#146ef5",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  fileName: {
    marginLeft: "10px",
    fontStyle: "italic",
  },
};

export default FileUpload;
