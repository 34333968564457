const Monitoring = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clip-path="url(#clip0_408_6690)">
        <path
          d="M18 14.25V3C18 2.40326 17.7629 1.83097 17.341 1.40901C16.919 0.987053 16.3467 0.75 15.75 0.75H2.25C1.65326 0.75 1.08097 0.987053 0.65901 1.40901C0.237053 1.83097 0 2.40326 0 3L0 14.25H8.25V15.75H4.5V17.25H13.5V15.75H9.75V14.25H18ZM1.5 3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25H15.75C15.9489 2.25 16.1397 2.32902 16.2803 2.46967C16.421 2.61032 16.5 2.80109 16.5 3V12.75H1.5V3ZM11.2238 6.75H15V8.25H12.0262L10.032 11.241L7.782 6.741L6.77625 8.25H3V6.75H5.97375L7.968 3.759L10.218 8.259L11.2238 6.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_408_6690">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Monitoring;
