import { useState } from "react";
import style from "./index.module.scss";

export const CustomInput = ({
  placeholder,
  onchange,
  value,
  inputClass,
  divClass,
  inputType,
}) => {
  return (
    <div className={`${style.inputWrap} divClass`}>
      <input
        className={`${style.inputField} inputClass`}
        onChange={(e) => {
          onchange(e.target.value);
        }}
        placeholder={placeholder}
        value={value}
        type={inputType}
      />
    </div>
  );
};

export const CustomDropDown = ({ dropDownItem, onSelect, selectedValue }) => {
  // const [selectedValue, setSelectedValue] = useState("");
  const handleChange = (event) => {
    const value = event.target.value;
    // setSelectedValue(value);
    onSelect(value);
  };

  return (
    <>
      <select
        className={style.dropdownWrap}
        value={selectedValue}
        onChange={handleChange}
      >
        <option value="" disabled>
          Select option
        </option>
        {dropDownItem?.map((item, index) => {
          return (
            <option key={index} value={item?.value}>
              {item?.label}
            </option>
          );
        })}
      </select>
    </>
  );
};
