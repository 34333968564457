import { memo, useCallback, useEffect, useState } from "react";
import CustomButton from "../../../../atoms/CustomButton";
import { Heading } from "../../../../atoms/Heading";
import Search from "../../../../atoms/Search";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import style from "./index.module.scss";
import NoContentCard from "../../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../../services";
import View from "../../../../icons/View";
import EditBlue from "../../../../icons/EditBlue";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Pagination from "../../../../atoms/Pagination";
import Delete from "../../../../icons/Delete";
import Modal from "../../../../atoms/modals/DeleteModal";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import Edit from "../../../../icons/EditBlue";
import CustomMenu from "../../../../molecules/CustomMenu";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
import { BrokerTypeData } from "../../../../../utils/staticData";
import moment from "moment";
import { downloadReport } from "../../../../../services/downloadService";
import Upload from "../../../../icons/Upload";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";
import { useSelector } from "react-redux";

const BrokerManagement = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t", "roleGroup"]);
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Brokers;
  const [brokerData, setBrokerData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    clientId: "",
    brokerStatus: "",
    isModalOpen: false, // Set initial state to false
  });
  const [filters, setFilters] = useState({});

  console.log("sel", selectedRow);

  const menu = [
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(`edit/${selectedRow?._id}`);
      },
    },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          name: selectedRow.broker_name,
          modalId: selectedRow._id,
          clientId: selectedRow.counterDetailsPopulated?._id,
          brokerStatus: selectedRow.counterDetailsPopulated?.brokerStatus?.[0],
        });
        setAnchorEl(null);
      },
    },
  ];

  const getBrokerData = async () => {
    try {
      const res = await getData({
        endpoint: "/broker",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          brokerTypes: JSON.stringify(filters?.["Broker Type"]),
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setBrokerData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getBrokerData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Broker Added Yet!"
          tag="Create Broker"
          type="Pricing"
          classes="d-flex align-items-center"
          {...(roleData && roleData?.["add/edit"]
            ? {
                handleClick: () => {
                  navigate("/dashboard/broker/add");
                },
                subtitle:
                  "Currently no broker added yet click on a Create Broker button to create broker ",
              }
            : {})}
        />
      </>
    );
  });
  const handleEdit = (data) => {
    navigate(`/dashboard/broker/add/${data?._id}`);
  };

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const transformedData = brokerData?.[0]?.roleData.flatMap((item, index) =>
  //   item.clientDetails.map((clientDetail, subIndex) => ({
  //     id: `${item.broker_name}-${item.fee_rate}-${clientDetail.client_id}`,
  //     broker_name: item.broker_name,
  //     fee_rate: item.fee_rate,
  //     broker_type: item.broker_type,
  //     client_name: clientDetail.client_name,
  //     counterParty_type: clientDetail.counterParty_type,
  //     createdAt: item.createdAt,
  //     updatedAt: item.updatedAt,
  //   })),
  // );

  const columns = [
    {
      field: "broker_name",
      headerName: "Broker Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.broker_name}</div>
        );
      },
    },
    {
      field: "fee_rate",
      headerName: "Fee Rate (Decimal)",
      flex: 1,
      renderCell: (params) => {
        return <div className={style.valueStyle}>{params?.row?.fee_rate}</div>;
      },
    },
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 1,
      renderCell: (params) => {
        const clientDetails =
          params?.row?.counterDetailsPopulated?.counter_name;
        return <div>{clientDetails}</div>;
      },
    },
    {
      field: "broker_type",
      headerName: "Broker Type",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.broker_type}</div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>
              {moment(params.row?.counterDetailsPopulated?.updatedAt).format(
                "YYYY-MM-DD",
              )}
            </span>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      renderCell: (params, index) => {
        return (
          <>
            <>
              <span
                type="button"
                onClick={(event) => handleStatusMenu(event, params.row)}
              >
                <HorizontalMenu />
              </span>

              <CustomMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && selectedRow === params.row}
                onClose={handleClose}
                items={getMenuList()}
              />
            </>
          </>
        );
      },
    },
  ];
  const getMenuList = () => {
    if (roleData && roleData?.["add/edit"] && roleData?.delete) {
      return menu;
    } else if (roleData && roleData?.delete) {
      return [menu[1]];
    } else if (roleData && roleData?.["add/edit"]) {
      return [menu[0]];
    } else return [];
  };
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "broker/delete",
        token: cookies.t,
        params: {
          id: modalStateDelete?.modalId,
          clientID: modalStateDelete?.clientId,
          brokerStatus: modalStateDelete?.brokerStatus,
        },
      });
      if (res) {
        toast.success("Broker Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        getBrokerData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const filtersData = {
    "Broker Type": BrokerTypeData,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "broker/exportBroker",
      token: cookies.t,
      fileName: "Brokers",
      params: {
        search: searchData,
        brokerTypes: JSON.stringify(filters?.["Broker Type"]),
      },
    });

    if (res) {
      toast.success("Brokers Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Brokers</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />
          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}

          <FilterComponent
            filterData={filtersData}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
          {roleData && roleData?.["add/edit"] && (
            <CustomButton
              text="Create Broker"
              type="btn-primary"
              handleClick={() => {
                navigate("/dashboard/broker/add");
              }}
            />
          )}
        </div>
      </div>
      <CustomDatagrid
        rows={brokerData?.data || []}
        getRowId={(row, index) =>
          `${
            row?.broker_name +
            row?.fee_rate +
            row?.broker_type +
            row?.counterDetailsPopulated?._id
          }`
        }
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {brokerData?.pageDetails?.totalCount > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={brokerData?.pageDetails?.totalCount}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Broker"
        name={modalStateDelete.name}
      />
    </>
  );
};

export default BrokerManagement;
