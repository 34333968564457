import { Formik } from "formik";
import { Heading } from "../../../../atoms/Heading";
import style from "./index.module.scss";
import CustomButton from "../../../../atoms/CustomButton";
import { getData, patchData, postData } from "../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import TextInput from "../../../../atoms/TextInput";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import QuillEditor from "../../../../atoms/QuillEditor/QuillEditor";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import MyDropdown from "../../../../atoms/MyDropdown";
import CounterDropDown from "../../../../molecules/CounterDataDropdown";
import moment from "moment";
import { LabelDisplay } from "../../counterparty/view";

const ClientMonitoring = ({ type, mode = "" }) => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t", "un"]);
  const [priorListData, setPriorListData] = useState([]);
  const [priorData, setPriorData] = useState([]);
  const [initialDataByID, setIntitalDataByID] = useState([]);
  const { id } = useParams();

  const initialValues = {
    type: "clientMonitoring",
    client_id: initialDataByID.client_id || "",
    client_name: initialDataByID.client_name || "",
    date: initialDataByID.note_date
      ? moment(initialDataByID.note_date).format("YYYY-MM-DD")
      : "",
    prior: "",
    details: {
      BMR: initialDataByID.details?.BMR || "",
      BMC: initialDataByID.details?.BMC || "",
      FI: initialDataByID.details?.FI || "",
      FBS: initialDataByID.details?.FBS || "",
      FCF: initialDataByID.details?.FCF || "",
      OSC: initialDataByID.details?.OSC || "",
      OSI: initialDataByID.details?.OSI || "",
      OI: initialDataByID.details?.OI || "",
      OBC: initialDataByID.details?.OBC || "",
      OBI: initialDataByID.details?.OBI || "",
      CO: initialDataByID.details?.CO || "",
      CP: initialDataByID.details?.CP || "",
      CASACM: initialDataByID.details?.CASACM || "",
      CPP: initialDataByID.details?.CPP || "",
      CRT: initialDataByID.details?.CRT || "",
      CL: initialDataByID.details?.CL || "",
      OTHERS: initialDataByID.details?.OTHERS || "",
    },
  };

  const handleSubmit = async (status, values) => {
    if (id) {
      try {
        console.log("Status :", status);
        const res = await patchData({
          endpoint: "notes/update",
          params: {
            id: id,
          },
          data: {
            type: values.type,
            client_name: values.client_name,
            client_id: values.client_id,
            note_date: values.date
              ? values.date
              : moment().utc().startOf("day").toISOString(),
            note_taker: atob(cookies.un),
            details: values.details,
            prior_call: values.prior,
            status: status,
          },
          token: cookies.t,
        });
        if (res) {
          toast.success("Notes Updated Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/notes");
        }
      } catch (err) {
        console.log("error", err);
      }
    } else {
      try {
        console.log("Status :", status);
        const res = await postData({
          endpoint: "notes/create",
          data: {
            type: values.type,
            client_name: values.client_name,
            client_id: values.client_id,
            note_date: values.date
              ? values.date
              : moment().utc().startOf("day").toISOString(),
            note_taker: atob(cookies.un),
            details: values.details,
            prior_call: values.prior,
            status: status,
          },
          token: cookies.t,
        });
        if (res) {
          toast.success("Notes Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/notes");
        }
      } catch (err) {
        console.log("error", err);
      }
    }
  };
  const getPriorCall = async () => {
    const res = await getData({
      endpoint: "notes/getPriorCallList",
      params: { note_type: initialValues.type },
      token: cookies.t,
    });
    setPriorListData(res?.data);
  };

  const getPriorDataByID = async (dataId, status) => {
    const res = await getData({
      endpoint: "notes/getNoteById",
      params: { id: dataId },
      token: cookies.t,
    });
    if (status === "initial") {
      setIntitalDataByID(res?.data);
    } else {
      setPriorData(res?.data);
    }
  };

  useEffect(() => {
    getPriorCall();
    if (id) {
      getPriorDataByID(id, "initial");
    }
  }, [id]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        key={Math.random}
        enableReinitialize
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,
          errors,
          values,
          handleBlur,
          resetForm,
          isSubmitting,
        }) => (
          <>
            <Breadcrumb
              labels={[
                "Notes",
                `${
                  mode === "View" ? "View" : "Create"
                } Existing Client Monitoring`,
              ]}
              stepsToRemove={id ? (mode === "View" ? 2 : 1) : 0}
            />
            <Heading className="addHeaderStyle">
              {mode === "View" ? "View" : "Create"} Existing Client Monitoring
            </Heading>
            <div
              className={`d-flex w-100 gap-3 ${mode !== "View" && "flex-wrap"}`}
            >
              {mode === "View" ? (
                <LabelDisplay
                  label={"Client Name"}
                  value={values.client_name}
                />
              ) : (
                <div style={{ width: "23.9%" }}>
                  <CounterDropDown
                    placeholder="Client Name"
                    onChange={(option) => {
                      setFieldValue("client_name", option.label);
                      setFieldValue("client_id", option?.value);
                      getPriorCall({ client_id: option?.value });
                    }}
                    selectedValue={values?.client_id}
                    format="all"
                    isError={errors.client_id}
                    touched={touched.client_id}
                    handleBlur={(e) => {
                      handleBlur(e);
                      setFieldTouched("client_id", true);
                    }}
                    errors={errors.client_id}
                    onlyGroup={true}
                  />
                </div>
              )}
              {mode === "View" ? (
                <LabelDisplay label={"Note Date"} value={values.date} />
              ) : (
                <div style={{ width: "23.9%", position: "relative" }}>
                  <DateSelector
                    placeholder="Note Date"
                    onChange={async (option) => {
                      setFieldValue("date", option);
                    }}
                    selectedDate={values.date}
                  />
                </div>
              )}
              {mode !== "View" && (
                <MyDropdown
                  placeholder="Prior Call"
                  data={priorListData}
                  onChange={(option) => {
                    setFieldValue("prior", option);
                    getPriorDataByID(option);
                  }}
                  selectedValue={values?.prior}
                  width="35.8%"
                />
              )}
            </div>
            <div className="mt-2">
              <QuillEditor
                label="Business Model-Recap"
                placeholderTextcription="Business Model-Recap"
                onChange={(content) => setFieldValue(`details.BMR`, content)}
                value={values.details.BMR}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Recap, value drivers, any Changes, rationale"}
                data={priorData?.details?.BMR}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Business Model - Changes"
                placeholderTextcription="Business Model - Changes"
                onChange={(content) => setFieldValue(`details.BMC`, content)}
                value={values.details.BMC}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "Any Changes to model or stratergy, rationale behind those changes"
                }
                data={priorData?.details?.BMC}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Financial - Income"
                placeholderTextcription="Financial - Income"
                onChange={(content) => setFieldValue(`details.FI`, content)}
                value={values.details.FI}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "Revenue trend and projections, gross margins, SG&A, net income"
                }
                data={priorData?.details?.FI}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Financial - Balance Sheet"
                placeholderTextcription="Financial - Balance Sheet"
                onChange={(content) => setFieldValue(`details.FBS`, content)}
                value={values.details.FBS}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "Distributions paid, fixed asset sales and purchases, additional debt and investments made"
                }
                data={priorData?.details?.FBS}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Financial - Cash Flow"
                placeholderTextcription="Financial - Cash Flow"
                onChange={(content) => setFieldValue(`details.FCF`, content)}
                value={values.details.FCF}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Cash on hand, working capital needs"}
                data={priorData?.details?.FCF}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Operational - Suppliers changes"
                placeholderTextcription="Operational - Suppliers changes"
                onChange={(content) => setFieldValue(`details.OSC`, content)}
                value={values.details.OSC}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "Change in suppliers, change in terms with suppliers and logistics. Changes in quality, availability and price"
                }
                data={priorData?.details?.OSC}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Operational - Suppliers Issues"
                placeholderTextcription="Operational - Suppliers Issues"
                onChange={(content) => setFieldValue(`details.OSI`, content)}
                value={values.details.OSI}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "Any delays, disputes, resolutions and payable performance"
                }
                data={priorData?.details?.OSI}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Operational - Inventory"
                placeholderTextcription="Operational - Inventory"
                onChange={(content) => setFieldValue(`details.OI`, content)}
                value={values.details.OI}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "Days inventory outstanding trend, aged inventory trend, %Po Vs general inventory"
                }
                data={priorData?.details?.OI}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Operational - Buyers Changes"
                placeholderTextcription="Operational - Buyers Changes"
                onChange={(content) => setFieldValue(`details.OBC`, content)}
                value={values.details.OBC}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "Trend in receivable performance (slower pay), change in clients, any client concentration changes"
                }
                data={priorData?.details?.OBC}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Operational - Buyers Issues"
                placeholderTextcription="Operational - Buyers Issues"
                onChange={(content) => setFieldValue(`details.OBI`, content)}
                value={values.details.OBI}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={
                  "Any disputes, dilution or bad debt, What is the credit policy and monitoring status?"
                }
                data={priorData?.details?.OBI}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Corporate - Ownership"
                placeholderTextcription="Corporate - Ownership"
                onChange={(content) => setFieldValue(`details.CO`, content)}
                value={values.details.CO}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Any Changes, rationale"}
                data={priorData?.details?.CO}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Corporate - Personal"
                placeholderTextcription="Corporate - Personal"
                onChange={(content) => setFieldValue(`details.CP`, content)}
                value={values.details.CP}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Any Changes, rationale"}
                data={priorData?.details?.CP}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Corporate - Authorised signatories and cash movement"
                placeholderTextcription="Corporate - Authorised signatories and cash movement"
                onChange={(content) => setFieldValue(`details.CASACM`, content)}
                value={values.details.CASACM}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Any Changes, rationale"}
                data={priorData?.details?.CASACM}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Corporate - Policies and procedure"
                placeholderTextcription="Corporate - Policies and procedure"
                onChange={(content) => setFieldValue(`details.CPP`, content)}
                value={values.details.CPP}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Any Changes, rationale"}
                data={priorData?.details?.CPP}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Corporate - Regulatory and Tax"
                placeholderTextcription="Corporate - Regulatory and Tax"
                onChange={(content) => setFieldValue(`details.CRT`, content)}
                value={values.details.CRT}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Any Changes, rationale and status with each"}
                data={priorData?.details?.CRT}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Corporate - Lawsuits"
                placeholderTextcription="Corporate - Lawsuits"
                onChange={(content) => setFieldValue(`details.CL`, content)}
                value={values.details.CL}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Any current, pending or threatend lawsuits"}
                data={priorData?.details?.CL}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Others"
                placeholderTextcription="Others"
                onChange={(content) => setFieldValue(`details.OTHERS`, content)}
                value={values.details.OTHERS}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Any other comments, feedback"}
                data={priorData?.details?.OTHERS}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div
              className={style.buttonWrap}
              style={{
                justifyContent: mode === "View" ? "end" : "space-between",
              }}
            >
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              {mode !== "View" && (
                <div className={style.resetAdd}>
                  <CustomButton
                    text="Save"
                    type="btn-outline-primary"
                    size="btn-lg"
                    handleClick={() => handleSubmit("Draft", values)}
                    disabled={isSubmitting}
                  />
                  <CustomButton
                    text={"Save & Share"}
                    type="btn-primary"
                    size="btn-lg"
                    handleClick={() => handleSubmit("Active", values)}
                    disabled={isSubmitting}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
export default ClientMonitoring;
