import React, { useCallback, useState, memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import Pagination from "../../../../atoms/Pagination";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import NoContentCard from "../../../../atoms/NoContentCard";

import { deleteData, getData, patchData } from "../../../../../services";
import { useCookies } from "react-cookie";
import CustomMenu from "../../../../molecules/CustomMenu";
import Assign from "../../../../icons/Assign";
import Delete from "../../../../icons/Delete";
import Modal from "../../../../atoms/modals/DeleteModal";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import moment from "moment/moment";

const content = {
  New: {
    title: "No Payments added yet!",
    subtitle:
      "No Payment added for review yet. Click on Add Payment to add new Payment.",
  },
  Reserves: {
    title: "No Payment added in Reserves yet!",
    subtitle: "Currently, no payment has been added to Reserves.",
  },
  Pending: {
    title: "No Payments added in Pending yet!",
    subtitle: "Currently, no payments are pending.",
  },
  Assigned: {
    title: "No Payment Assigned yet!",
    subtitle: "Currently, no payments have been assigned for processing.",
  },
};

const Table = ({
  type,
  searchData,
  getPaymentStatus,
  roleData,
  // setAssignModalState,
  // assignModalState,
}) => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const [paymentData, setPaymentData] = useState([]);
  const [modalState, setModalState] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
    data: null,
  });
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
    data: null,
  });

  const GetPaymentData = async () => {
    try {
      const res = await getData({
        endpoint: "/payment",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          status:
            type === "Reserves"
              ? ["Reserves", "ReservesHold"]
              : type === "Pending"
              ? ["Pending", "ReturnToClient"]
              : [type],
        },
      });
      setPaymentData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    GetPaymentData();
  }, [itemsPerPage, currentPage, searchData, type]);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleModalClose = () => {
    setModalState(() => ({
      isModalOpen: false,
      modalId: "",
      modalTitle: "",
      data: null,
    }));
  };
  const handleModalDelete = () => {
    modalStateDelete(() => ({
      isModalOpen: false,
      modalId: "",
      modalTitle: "",
      data: null,
    }));
  };

  const handleMarkAsReserve = async () => {
    try {
      const res = await patchData({
        endpoint: "payment/updateStatus",
        token: cookies.t,
        params: {
          ids: [modalState.modalId],
          status:
            modalState.modalTitle === "Mark As Reserve (Hold)"
              ? "ReservesHold"
              : modalState.modalTitle === "Mark As Sent to Client"
              ? "ReturnToClient"
              : "Pending",
        },
      });
      if (res) {
        toast.success("Payment Status changed", {
          duration: 1000,
          style: confimationStyles,
        });
        GetPaymentData();
        getPaymentStatus();
        setModalState(() => ({
          isModalOpen: false,
          modalTitle: "",
          modalId: "",
          data: null,
        }));
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleDeletePayment = async () => {
    try {
      const res = await deleteData({
        endpoint: "payment/delete",
        token: cookies.t,
        params: {
          paymentId: modalStateDelete?.modalId,
          detailId: modalStateDelete.data._id,
        },
      });
      if (res) {
        GetPaymentData();
        getPaymentStatus();
        toast.success("Payment Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        setModalStateDelete(() => ({
          isModalOpen: false,
          modalTitle: "",
          modalId: "",
          data: null,
        }));
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const menu = [
    (() => {
      let menuItem = [];
      switch (type) {
        case "New":
          menuItem.push({
            icon: <Assign />,
            label: "Assign",
            onClick: () => {
              // setAssignModalState(() => ({
              //   isModalOpen: true,
              //   modalId: selectedRow?._id,
              //   modalTitle: "Payment Assignment:",
              //   data: selectedRow,
              // }));
              // handleClose();
              navigate(`/dashboard/payments/review/view/${selectedRow?._id}`);
            },
          });
          menuItem.push({
            icon: <Delete />,
            label: "Delete",
            onClick: () => {
              setModalStateDelete(() => ({
                isModalOpen: true,
                modalTitle: "Client",
                modalId: selectedRow?._id,
                data: selectedRow,
              }));
              handleClose();
            },
          });
          break;
        case "Reserves":
          !selectedRow?.status === "ReservesHold" &&
            menuItem.push({
              icon: <Assign />,
              label: "Assign",
              onClick: () => {
                // setAssignModalState(() => ({
                //   isModalOpen: true,
                //   modalId: selectedRow?._id,
                //   modalTitle: "Payment Assignment:",
                //   data: selectedRow,
                // }));
                // handleClose();
                navigate(`/dashboard/payments/review/view/${selectedRow?._id}`);
              },
            });
          menuItem.push({
            icon: <Assign />,
            label: "Mark As Reserve (Return)",
            onClick: () => {
              setModalState(() => ({
                isModalOpen: true,
                modalTitle: "Mark As Reserve (Return)",
                modalId: selectedRow?._id,
                data: selectedRow,
              }));
              handleClose();
            },
          });
          menuItem.push({
            icon: <Assign />,
            label: "Mark As Reserve (Hold)",
            onClick: () => {
              setModalState(() => ({
                isModalOpen: true,
                modalTitle: "Mark As Reserve (Hold)",
                modalId: selectedRow?._id,
                data: selectedRow,
              }));
              handleClose();
            },
          });
          menuItem.push({
            icon: <Delete />,
            label: "Delete",
            onClick: () => {
              setModalStateDelete(() => ({
                isModalOpen: true,
                modalTitle: "Client",
                modalId: selectedRow?._id,
                data: selectedRow,
              }));
              handleClose();
            },
          });
          break;
        case "Pending":
          menuItem.push({
            icon: <Assign />,
            label: "Mark As Sent to Client",
            onClick: () => {
              setModalState(() => ({
                isModalOpen: true,
                modalTitle: "Mark As Sent to Client",
                modalId: selectedRow?._id,
                data: selectedRow,
              }));
              handleClose();
            },
          });
          menuItem.push({
            icon: <Delete />,
            label: "Delete",
            onClick: () => {
              setModalStateDelete(() => ({
                isModalOpen: true,
                modalTitle: "Client",
                modalId: selectedRow?._id,
                data: selectedRow,
              }));
              handleClose();
            },
          });
          break;
        case "Assigned":
          menuItem.push({
            icon: <Assign />,
            label: "Assign",
            onClick: () => {
              // setModalStateDelete(() => ({
              //   isModalOpen: true,
              //   modalTitle: "Client",
              //   modalId: selectedRow?._id,
              //   data: selectedRow,
              // }));
              // handleClose();
            },
          });
          break;
        default:
          break;
      }
      return menuItem;
    })(),
  ];

  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 0.8,
    },
    {
      field: "obligor_name",
      headerName: "Obligor",
      flex: 0.8,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.amount}</div>;
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.currency}</div>;
      },
    },
    {
      field: "date",
      headerName: "Date",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{moment(params?.row?.createdAt).format("YYYY-MM-DD")}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params?.row?.status}</div>;
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.4,
      renderCell: (params, index) => {
        return (
          <>
            <div>
              <span
                type="button"
                onClick={(event) => handleStatusMenu(event, params.row)}
              >
                <HorizontalMenu />
              </span>
            </div>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              // items={menu?.[0]}
              items={getMenuList()}
            />
          </>
        );
      },
    },
  ];

  const getMenuList = () => {
    const getMenuByRoleNew = () => {
      if (roleData?.["add/edit"] && roleData?.delete) {
        return menu[0];
      } else if (roleData?.delete) {
        return [menu[0][3]];
      } else if (roleData?.["add/edit"]) {
        return [menu[0][0], menu[0][1], menu[0][2]];
      } else {
        return [];
      }
    };
    const getMenuByRoleReserves = () => {
      if (roleData?.["add/edit"] && roleData?.delete) {
        return menu[0];
      } else if (roleData?.delete) {
        return [menu[0][2]];
      } else if (roleData?.["add/edit"]) {
        return [menu[0][0], menu[0][1]];
      } else {
        return [];
      }
    };

    switch (type) {
      case "New":
        return getMenuByRoleNew();
      case "Reserves":
        return getMenuByRoleReserves();
      case "Pending":
        return getMenuByRoleReserves();
      case "Assigned":
        return getMenuByRoleReserves();
      default:
        return [];
    }
  };

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title={content[type].title}
          subtitle={content[type].subtitle}
          type="Pricing"
          classes="d-flex align-items-center"
        />
      </>
    );
  });
  const handleEdit = (data) => {
    navigate(`/dashboard/payments/add/${data?._id}`);
  };
  return (
    <>
      <CustomDatagrid
        rows={paymentData?.[0]?.paymentData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        // checkRequired
        // setSelectedRowIds={setSelectedRowIds}
      />
      {paymentData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={paymentData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <Modal
        type
        description={modalState.modalTitle}
        isActive={modalState.isModalOpen}
        title={modalState.modalTitle}
        onClose={handleModalClose}
        onClick={handleMarkAsReserve}
      ></Modal>
      <Modal
        type
        description={modalStateDelete.modalTitle}
        isActive={modalStateDelete.isModalOpen}
        title={modalStateDelete.modalTitle}
        onClose={handleModalDelete}
        onClick={handleDeletePayment}
      ></Modal>
    </>
  );
};

export default Table;
