import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

export const Heading = ({ label, children, className, style }) => {
  const HeadingLevel = label;

  return (
    <HeadingLevel className={`${className}`} style={style}>
      {children}
    </HeadingLevel>
  );
};

Heading.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

Heading.defaultProps = {
  label: "h1",
};
