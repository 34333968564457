import React from 'react';

const Notes = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3_57)">
        <path
          d="M14.25 1.5V0H12.75V1.5H11.25V0H9.75V1.5H8.25V0H6.75V1.5H5.25V0H3.75V1.5H2.25V15.75C2.25 16.3467 2.48705 16.919 2.90901 17.341C3.33097 17.7629 3.90326 18 4.5 18H13.5C14.0967 18 14.669 17.7629 15.091 17.341C15.5129 16.919 15.75 16.3467 15.75 15.75V1.5H14.25ZM14.25 15.75C14.25 15.9489 14.171 16.1397 14.0303 16.2803C13.8897 16.421 13.6989 16.5 13.5 16.5H4.5C4.30109 16.5 4.11032 16.421 3.96967 16.2803C3.82902 16.1397 3.75 15.9489 3.75 15.75V3H14.25V15.75ZM12.75 6.75H5.25V5.25H12.75V6.75ZM12.75 9.75H5.25V8.25H12.75V9.75ZM9.75 12.75H5.25V11.25H9.75V12.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_57">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Notes;
