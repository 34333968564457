import { Heading } from "../../../../atoms/Heading";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import SelectDropdown from "../../../../atoms/Dropdown";
import { useEffect, useState } from "react";
import { getData } from "../../../../../services";
import { useCookies } from "react-cookie";
import Supplier from "./supplier";
import Obligor from "./obligor";
import Client from "./client";
import MyDropdown from "../../../../atoms/MyDropdown";

const AddFunding = () => {
  const [cookies] = useCookies(["t"]);
  const [selectedClient, setSelectedClient] = useState({
    clientName: "",
    clientId: "",
  });
  const [clientData, setClientData] = useState([]);
  const [supplierObligorData, setSupplierObligorData] = useState([]);
  const [selectedTransactionType, setSelectedTransactionType] = useState({
    selectedType: "",
  });
  const [transactionTypeData, setTransactionTypeData] = useState([]);

  const getClientData = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getDataWithCounterType",
        token: cookies.t,
        params: {
          conterparty_type: "Client",
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getClientData();
  }, []);

  const getTranscationType = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/clientSupplierAndObligor",
        token: cookies.t,
        params: {
          id: selectedClient?.clientId,
        },
      });
      const updatedData = res?.data.flatMap((item) => {
        if (item?.tag === "Obligor") {
          return { ...item, tag: "Factoring/AR" };
        } else if (item?.tag === "Client") {
          const newTags = [];
          newTags.push({ ...item, tag: "Note" });
          newTags.push({ ...item, tag: "Revolver" });
          if (item?.transaction_type) {
            newTags.push({ ...item, tag: item.transaction_type });
          }
          return newTags;
        } else if (item?.tag === "Supplier") {
          const newTags = [];
          newTags.push({ ...item, tag: "Supplier Credit" });
          newTags.push({ ...item, tag: "Supplier Credit-1" });
          return newTags;
        }
        return item;
      });
      const uniqueTags = [...new Set(updatedData.map((item) => item.tag))];
      const tempdata = uniqueTags.map((tag) => ({ label: tag, value: tag }));
      setTransactionTypeData(tempdata);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (selectedClient?.clientId) {
      getTranscationType();
    }
  }, [selectedClient?.clientId]);

  useEffect(() => {
    if (selectedClient.clientId && selectedTransactionType.selectedType) {
      getSupplierAndObligor();
    }
  }, [selectedClient, selectedTransactionType]);

  const getSupplierAndObligor = async (value) => {
    try {
      const res = await getData({
        endpoint: "couterParty/getSupplierAndObligorById",
        token: cookies.t,
        params: {
          client_id: selectedClient?.clientId,
          counterparty_type: selectedTransactionType.selectedType,
        },
      });
      const tempData = res?.data?.map((item) => {
        return {
          label: item?.counter_name,
          value: item?._id,
        };
      });

      setSupplierObligorData([
        ...tempData,
        { label: selectedClient.clientName, value: selectedClient.clientId },
      ]);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <Breadcrumb labels={["Funding", "Add Funding"]} />
      <Heading className="addHeaderStyle">Add Funding</Heading>
      <div className="row">
        <div className="col-3 mt-2">
          <SelectDropdown
            data={clientData}
            placeholder="Client Name"
            onChange={(option) => {
              setSelectedClient(() => ({
                clientName: option.label,
                clientId: option.value,
              }));
              setSelectedTransactionType(() => ({
                selectedType: "",
              }));
            }}
            format="all"
            selectedValue={selectedClient.clientId}
          />
        </div>
        <div className="col-3 mt-2">
          <MyDropdown
            data={transactionTypeData}
            placeholder="Transaction Type"
            onChange={(option) => {
              console.log("option", option);
              if (option === "Factoring/AR") {
                setSelectedTransactionType(() => ({
                  selectedType: "Factoring/AR",
                }));
              } else if (option === "Supplier Credit") {
                setSelectedTransactionType(() => ({
                  selectedType: "Supplier Credit",
                }));
              } else if (option === "Supplier Credit-1") {
                setSelectedTransactionType(() => ({
                  selectedType: "Supplier Credit-1",
                }));
              } else if (option === "Note") {
                setSelectedTransactionType(() => ({
                  selectedType: "Note",
                }));
              } else if (option === "Revolver") {
                setSelectedTransactionType(() => ({
                  selectedType: "Revolver",
                }));
              }
            }}
            selectedValue={
              selectedTransactionType.selectedType === "Obligor"
                ? "Factoring/AR"
                : selectedTransactionType.selectedType === "Supplier"
                ? "Supplier Credit"
                : selectedTransactionType.selectedType === "Client"
                ? "Note"
                : selectedTransactionType.selectedType === "Supplier Credit-1"
                ? "Supplier Credit-1"
                : selectedTransactionType.selectedType === "Revolver"
                ? "Revolver"
                : ""
            }
          />
        </div>
      </div>

      {(selectedTransactionType.selectedType === "Supplier Credit" ||
        selectedTransactionType.selectedType === "Supplier Credit-1") && (
        <Supplier
          supplierObligorData={supplierObligorData}
          clientDetails={selectedClient}
          selectedTransactionType={selectedTransactionType}
        />
      )}
      {selectedTransactionType.selectedType === "Factoring/AR" && (
        <Obligor
          supplierObligorData={supplierObligorData}
          clientDetails={selectedClient}
        />
      )}
      {(selectedTransactionType.selectedType === "Note" ||
        selectedTransactionType.selectedType === "Revolver") && (
        <Client
          clientDetails={selectedClient}
          selectedTransactionType={selectedTransactionType}
        />
      )}
    </>
  );
};
export default AddFunding;
