import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  customDropdownStyles,
  filterDropdownStyles,
} from "../../../assets/styles/components/dropdown";
import "./styles.scss";

const SelectDropdown = ({
  id,
  multiSelect = false,
  selectedValue,
  onChange,
  classes,
  placeholder,
  data,
  format,
  isDisabled,
  type,
  isClearable,
  onClear,
  label,
  menuOpen,
}) => {
  const options = data || [];

  const [selectedOptions, setSelectedOptions] = useState(null);
  const [isFocused, setIsFocused] = useState(selectedValue ? true : false);

  useEffect(() => {
    if (multiSelect && selectedValue) {
      const initialSelectedOptions = options?.filter((option) =>
        selectedValue?.includes(option.value),
      );
      setSelectedOptions(initialSelectedOptions);
    } else if (!multiSelect && selectedValue != null) {
      const initialSelectedOption =
        options?.find((option) => option?.value === selectedValue) || null;
      setSelectedOptions(initialSelectedOption);
    } else {
      const defaultSelectedOptions = multiSelect ? [] : null;
      setSelectedOptions(defaultSelectedOptions);
    }

    if (selectedValue) setIsFocused(true);
  }, [selectedValue, multiSelect, options]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);

    if (multiSelect) {
      let selectedValues;
      if (format === "all") {
        selectedValues = selectedOptions?.map((option) => option);
      } else {
        selectedValues = selectedOptions?.map((option) => option?.value);
      }
      onChange(selectedValues);
    } else {
      if (!selectedOptions) {
        onClear && onClear();
      }
      if (format === "all") {
        onChange(selectedOptions);
      } else {
        onChange(selectedOptions ? selectedOptions?.value : null);
      }
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (!selectedOptions || selectedOptions.length === 0) {
      setIsFocused(false);
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <Select
          className={`basic-single ${classes}`}
          classNamePrefix="select"
          options={options}
          value={selectedOptions}
          isMulti={multiSelect}
          onChange={handleSelectChange}
          styles={
            type === "filter" ? filterDropdownStyles : customDropdownStyles
          }
          key={id}
          placeholder=""
          isDisabled={isDisabled}
          isClearable={isClearable}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <label
          className={`input-placeholder-small dropdown-label ${
            isFocused || (selectedOptions && selectedOptions.length > 0)
              ? "active"
              : ""
          }`}
        >
          {placeholder}
        </label>
      </div>
    </>
  );
};

export default SelectDropdown;
