import { Formik } from "formik";
import { Heading } from "../../../../../atoms/Heading";
import style from "./index.module.scss";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import CustomButton from "../../../../../atoms/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import {
  CarrierNameData,
  CurrencyData,
  LimitTypeData,
  PolicyNameData,
  ReportingData,
} from "../../../../../../utils/staticData";
import { getData, patchData, postData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { useEffect, useState } from "react";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import MyDropdown from "../../../../../atoms/MyDropdown";
import MyInput from "../../../../../atoms/MyInput";
import * as Yup from "yup";

const AddInsurance = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [editedInsuranceData, setEditedInsurance] = useState({});
  const [counterError, setCounterError] = useState("");
  const [clientData, setClientData] = useState();
  const initialValues = {
    obligor_name: editedInsuranceData?.obligor_name || "",
    obligor_id: editedInsuranceData?.counterDetails?.[0] || "",
    limit: editedInsuranceData?.limit || "",
    currency: editedInsuranceData?.currency || "",
    limit_type: editedInsuranceData?.limit_type || "",
    policy_name: editedInsuranceData?.policy_name || "",
    carrier_name: editedInsuranceData?.carrier_name || "",
    reporting_terms: editedInsuranceData?.reporting_terms || "",
    start_date: editedInsuranceData?.start_date || "",
    end_date: editedInsuranceData?.end_date || "",
  };

  console.log("");

  const validationSchema = Yup.object().shape({
    obligor_id: Yup.string().required("Obligor is required"),
    limit: Yup.number()
      .required("Limit is required")
      .test(
        "is-decimal",
        "Limit must have at most 2 decimal places",
        (value) => {
          if (value == null) return true; // Skip validation if value is not set (handled by required)
          return /^\d+(\.\d{1,2})?$/.test(value);
        },
      ),
    currency: Yup.string().required("Currency is required"),
    limit_type: Yup.string().required("Limit Type is required"),
    policy_name: Yup.string().required("Policy name is required"),
    carrier_name: Yup.string().required("Carrier name is required"),
    reporting_terms: Yup.string().required("Reporting terms is required"),
    start_date: Yup.string().required("Start date is required"),
    end_date: Yup.string().required("End Date is required"),
  });
  const getInsuranceDataById = async () => {
    try {
      const res = await getData({
        endpoint: "insurance/getInsuranceById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setEditedInsurance(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (id) {
      getInsuranceDataById();
    }
  }, [id]);

  const getClientData = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getDataWithCounterType",
        token: cookies.t,
        params: {
          conterparty_type: "Obligor",
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getClientData();
  }, []);

  const handleSubmit = async (values) => {
    const dataToClient = {
      limit: values?.limit,
      currency: values?.currency,
      limit_type: values?.limit_type,
      policy_name: values?.policy_name,
      carrier_name: values?.carrier_name,
      reporting_terms: values?.reporting_terms,
      start_date: values?.start_date,
      end_date: values?.end_date,
      counterDetails: [values?.obligor_id],
    };
    if (id) {
      const res = await patchData({
        endpoint: "insurance/updateInsurance",
        token: cookies.t,
        params: {
          id: id,
        },
        data: dataToClient,
      });
      if (res) {
        toast.success("Insurance Details Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        navigate("/dashboard/insurance");
      }
    } else {
      try {
        const res = await postData({
          endpoint: "insurance/createInsurance",
          token: cookies.t,
          data: dataToClient,
        });
        if (res) {
          toast.success("Insurance Details Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/insurance");
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const checkCounterExists = async (newVal) => {
    const res = await getData({
      endpoint: "couterParty/getCounterPartyById",
      token: cookies.t,
      params: {
        id: newVal,
      },
    });
    if (
      res?.data?.insuranceDetails?.[0] &&
      res?.data?.insuranceDetails.length === 1
    ) {
      setCounterError("Obligor already has one insurance");
    } else {
      setCounterError("");
    }
  };

  console.log("CounterError :", counterError);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          validateForm,
          resetForm,
          setFieldTouched,
          isSubmitting,
        }) => (
          <>
            <Breadcrumb
              labels={["Insurance", `${!id ? "Create" : "Update"} Insurance`]}
            />

            <Heading className={style.AddSignerStyle}>{`${
              !id ? "Create" : "Update"
            } Insurance`}</Heading>
            <div
              className="d-flex flex-wrap w-100"
              style={{ columnGap: "1.36%", rowGap: "16px" }}
            >
              <MyDropdown
                placeholder="Obligor"
                data={clientData}
                onChange={async (option) => {
                  await setFieldValue("obligor_name", option?.label);
                  await setFieldValue("obligor_id", option?.value);
                  await validateForm();
                  await checkCounterExists(option?.value);
                }}
                selectedValue={values?.obligor_id}
                width="23.9%"
                format="all"
                isError={""}
                touched={touched.obligor_id}
                handleBlur={(e) => {
                  handleBlur(e);
                  setFieldTouched("obligor_id", true);
                }}
                info={counterError}
                errors={errors.obligor_id}
                isDisabled={id ? true : false}
              />
              {console.log("values :", values)}
              <MyInput
                type="number"
                name="limit"
                customholder="Limit"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                InputHeight="small"
                width="23.9%"
                onKeyDown={(e) => {
                  const invalidChars = ["e", "E", "+", "-"];
                  if (invalidChars.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              <MyDropdown
                placeholder="Currency"
                data={CurrencyData}
                onChange={(option) => {
                  setFieldValue("currency", option);
                }}
                selectedValue={values?.currency}
                width="23.9%"
                isError={values?.currency}
                touched={touched.currency}
                handleBlur={(e) => {
                  handleBlur(e);
                  setFieldTouched("currency", true);
                }}
                errors={errors.currency}
              />
              <MyDropdown
                placeholder="Limit Type"
                data={LimitTypeData}
                onChange={(option) => {
                  setFieldValue("limit_type", option);
                }}
                selectedValue={values?.limit_type}
                width="23.9%"
                isError={values?.limit_type}
                touched={touched.limit_type}
                handleBlur={(e) => {
                  handleBlur(e);
                  setFieldTouched("limit_type", true);
                }}
                errors={errors.limit_type}
              />
              <MyDropdown
                placeholder="Policy Name"
                data={PolicyNameData}
                onChange={(option) => {
                  setFieldValue("policy_name", option);
                }}
                selectedValue={values?.policy_name}
                width="23.9%"
                isError={values?.policy_name}
                touched={touched.policy_name}
                handleBlur={(e) => {
                  handleBlur(e);
                  setFieldTouched("policy_name", true);
                }}
                errors={errors.policy_name}
              />
              <MyDropdown
                placeholder="Carrier Name"
                data={CarrierNameData}
                onChange={(option) => {
                  setFieldValue("carrier_name", option);
                }}
                selectedValue={values?.carrier_name}
                width="23.9%"
                isError={values?.carrier_name}
                touched={touched.carrier_name}
                handleBlur={(e) => {
                  handleBlur(e);
                  setFieldTouched("carrier_name", true);
                }}
                errors={errors.carrier_name}
              />
              <MyDropdown
                placeholder="Reporting Terms"
                data={ReportingData}
                onChange={(option) => {
                  setFieldValue("reporting_terms", option);
                }}
                selectedValue={values?.reporting_terms}
                width="23.9%"
                isError={values?.reporting_terms}
                touched={touched.reporting_terms}
                handleBlur={(e) => {
                  handleBlur(e);
                  setFieldTouched("reporting_terms", true);
                }}
                errors={errors.reporting_terms}
              />
              <div style={{ width: "23.9%", position: "relative" }}>
                <DateSelector
                  placeholder="Select Start Date"
                  onChange={async (option) => {
                    setFieldValue("start_date", option);
                  }}
                  selectedDate={values.start_date}
                  isError={values?.start_date}
                  touched={touched.start_date}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("start_date", true);
                  }}
                  errors={errors.start_date}
                />
              </div>
              <div style={{ width: "23.9%", position: "relative" }}>
                <DateSelector
                  placeholder="Select End Date"
                  onChange={async (option) => {
                    setFieldValue("end_date", option);
                  }}
                  selectedDate={values.end_date}
                  isError={values?.end_date}
                  touched={touched.end_date}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("end_date", true);
                  }}
                  errors={errors.end_date}
                />
              </div>
            </div>
            <div className={style.buttonWrap}>
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <div className={style.resetAdd}>
                <CustomButton
                  text="Reset"
                  type="btn-outline-primary"
                  size="btn-lg"
                  handleClick={() => resetForm()}
                />
                <button
                  className="btn-primary btn-lg"
                  type="submit"
                  disabled={!isValid || !dirty || isSubmitting || counterError}
                  style={{ width: "100%" }}
                  onClick={() => handleSubmit(values)}
                >
                  {id ? "Update" : "Create"}
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
export default AddInsurance;
