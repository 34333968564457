import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../atoms/CustomButton";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import { useMemo, useState } from "react";
import TabStrip from "../../../atoms/tabstrip";
import { Box } from "@mui/material";
import Table from "./Table";
import { useCookies } from "react-cookie";
import { getData } from "../../../../services";
import Modal from "../../../atoms/modals/Modal";
import AdvReqModal from "./viewFunding/advReqModal/index.jsx";
import SubmittedModal from "./viewFunding/submittedModal/index.jsx";
import ApprovedModal from "./viewFunding/approved/index.jsx";
import FilterComponent from "../../../molecules/filters/FilterComponent.jsx";
import { fundingStatusData } from "../../../../utils/staticData.js";
import AllocatedModal from "./viewFunding/allocatedModal/index.jsx";
import { useSelector } from "react-redux";

const FundingManagement = () => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Funding;
  const [searchData, setSearchData] = useState();
  const [activeTab, setActiveTab] = useState("Draft");
  const [statusData, setStatusData] = useState();
  const [modalStateAdvReq, setModalStateAdvReq] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false,
  });
  const [modalStateSubmitted, setModalStateSubmitted] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false,
  });
  const [modalStateApproved, setModalStateApproved] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false,
  });
  const [modalStateAllocated, setModalStateAllocated] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [filters, setFilters] = useState({});

  const handleOnClose = () => {
    setModalStateAdvReq(() => ({
      isModalOpen: false,
    }));
  };

  const handleOnCloseSubmitted = () => {
    setModalStateSubmitted(() => ({
      isModalOpen: false,
    }));
  };
  const handleOnCloseApproved = () => {
    setModalStateApproved(() => ({
      isModalOpen: false,
    }));
  };
  const handleOnCloseAllocated = () => {
    setModalStateAllocated(() => ({
      isModalOpen: false,
    }));
  };

  const getAllFundingStatus = async () => {
    const res = await getData({
      endpoint: "funding/getAllFundingStatus",
      token: cookies.t,
    });
    if (res) {
      setStatusData(res.data);
    }
  };
  useEffect(() => {
    getAllFundingStatus();
  }, []);

  const data = useMemo(
    () => [
      {
        id: 1,
        label: "Draft",
        view: (
          <Table
            type="Draft"
            getAllFundingStatus={getAllFundingStatus}
            roleData={roleData}
            searchData={searchData}
          />
        ),
        badge: statusData?.Draft || 0,
      },
      {
        id: 2,
        label: "Advance Request",
        view: (
          <Table
            type="Advance Request"
            setModalStateAdvReq={setModalStateAdvReq}
            getAllFundingStatus={getAllFundingStatus}
            roleData={roleData}
            modalStateAdvReq={modalStateAdvReq}
            searchData={searchData}
          />
        ),
        badge: statusData?.["Advance Request"] || 0,
      },
      {
        id: 3,
        label: "Submitted",
        view: (
          <Table
            type="Submitted"
            setModalStateSubmitted={setModalStateSubmitted}
            getAllFundingStatus={getAllFundingStatus}
            roleData={roleData}
            modalStateSubmitted={modalStateSubmitted}
            searchData={searchData}
          />
        ),
        badge: statusData?.Submitted || 0,
      },
      {
        id: 4,
        label: "Approved",
        view: (
          <Table
            type="Approved"
            setModalStateApproved={setModalStateApproved}
            getAllFundingStatus={getAllFundingStatus}
            roleData={roleData}
            modalStateApproved={modalStateApproved}
            searchData={searchData}
          />
        ),
        badge: statusData?.Approved || 0,
      },
      {
        id: 5,
        label: "Allocated",
        view: (
          <Table
            type="Allocated"
            getAllFundingStatus={getAllFundingStatus}
            setModalStateAllocated={setModalStateAllocated}
            roleData={roleData}
            modalStateAllocated={modalStateAllocated}
            searchData={searchData}
          />
        ),
        badge: statusData?.Allocated || 0,
      },
      {
        id: 6,
        label: "Funded",
        view: (
          <Table
            type="Funded"
            getAllFundingStatus={getAllFundingStatus}
            roleData={roleData}
            searchData={searchData}
          />
        ),
        badge: statusData?.Funded || 0,
      },
      {
        id: 7,
        label: "Closed",
        view: (
          <Table
            type="Closed"
            getAllFundingStatus={getAllFundingStatus}
            roleData={roleData}
            searchData={searchData}
          />
        ),
        badge: statusData?.Closed || 0,
      },
      {
        id: 8,
        label: "Archive",
        view: (
          <Table
            type="Archive"
            getAllFundingStatus={getAllFundingStatus}
            roleData={roleData}
            searchData={searchData}
          />
        ),
        badge: statusData?.Archive || 0,
      },
    ],
    [statusData, searchData],
  );

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };
  const filterData = {
    status: fundingStatusData,
  };
  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Funding</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            onSearchEmit={(data) => {
              console.log("data", data);
              setSearchData(data);
            }}
          />
          <FilterComponent
            filterData={filterData}
            onFilterChange={(filter) => {
              setFilters(filter);
            }}
          />
          {roleData && roleData?.["add/edit"] && (
            <CustomButton
              text="Create funding"
              type="btn-primary"
              handleClick={() => {
                navigate("/dashboard/funding/add");
              }}
            />
          )}
        </div>
      </div>
      <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
      <Box sx={{ width: "100%" }}>
        <Box>{_getComponent(activeTab)}</Box>
      </Box>
      <Modal
        isActive={modalStateAdvReq.isModalOpen}
        title={modalStateAdvReq.modalTitle}
        onClose={handleOnClose}
        width="850px"
      >
        <AdvReqModal
          modalStateAdvReq={modalStateAdvReq}
          setModalStateAdvReq={setModalStateAdvReq}
          getAllFundingStatus={getAllFundingStatus}
        />
      </Modal>
      <Modal
        isActive={modalStateSubmitted.isModalOpen}
        title={modalStateSubmitted.modalTitle}
        onClose={handleOnCloseSubmitted}
        width="850px"
      >
        <SubmittedModal
          modalStateSubmitted={modalStateSubmitted}
          setModalStateSubmitted={setModalStateSubmitted}
          getAllFundingStatus={getAllFundingStatus}
        />
      </Modal>
      <Modal
        isActive={modalStateApproved.isModalOpen}
        title={modalStateApproved.modalTitle}
        onClose={handleOnCloseApproved}
        width="850px"
        overflow="visible"
      >
        <ApprovedModal
          modalStateApproved={modalStateApproved}
          setModalStateApproved={setModalStateApproved}
          getAllFundingStatus={getAllFundingStatus}
        />
      </Modal>
      <Modal
        isActive={modalStateAllocated.isModalOpen}
        title={modalStateAllocated.modalTitle}
        onClose={handleOnCloseAllocated}
        width="850px"
      >
        <AllocatedModal
          modalStateAllocated={modalStateAllocated}
          setModalStateAllocated={setModalStateAllocated}
          getAllFundingStatus={getAllFundingStatus}
        />
      </Modal>
    </>
  );
};

export default FundingManagement;
