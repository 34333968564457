const moment = require("moment");

// Function to determine which base rates apply between createdAt and dueDate
export const getApplicableBaseRates = (rates, createdAt, dueDate) => {
  const createdAtMoment = moment(createdAt);
  const dueDateMoment = moment(dueDate);

  const applicableRates = [];

  for (let i = 0; i < rates.length; i++) {
    const currentRateDate = moment(rates[i].date);
    const nextRateDate =
      i < rates.length - 1
        ? moment(rates[i + 1].date)
        : dueDateMoment.clone().add(1, "days");

    const effectiveStart = currentRateDate;
    const effectiveEnd = nextRateDate;

    if (
      createdAtMoment.isBefore(effectiveEnd) &&
      dueDateMoment.isAfter(effectiveStart)
    ) {
      const startDate = createdAtMoment.isAfter(effectiveStart)
        ? createdAtMoment
        : effectiveStart;
      const endDate = dueDateMoment.isBefore(effectiveEnd)
        ? dueDateMoment
        : effectiveEnd;

      applicableRates.push({
        base_rate: parseFloat(rates[i].base_rate), // Convert to float for calculations
        range: {
          start: startDate.format("YYYY-MM-DD"),
          end: endDate.format("YYYY-MM-DD"),
        },
      });
    }
  }

  return applicableRates;
};

// Function to calculate the final base rate
export const calculateFinalBaseRate = (applicableRates) => {
  if (applicableRates.length === 0) return 0; // No rates applicable

  const totalBaseRate = applicableRates.reduce(
    (total, rate) => total + rate.base_rate,
    0,
  );
  return totalBaseRate; // You can also compute average if needed
};
