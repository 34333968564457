import PropTypes from "prop-types";
const EditBlue = ({ className, width = "23", height = "23" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7748 5.2444C17.0893 5.56984 17.0893 6.09748 16.7748 6.42292L6.30796 17.2562C6.15697 17.4125 5.95217 17.5003 5.73863 17.5003H2.51804C2.3045 17.5003 2.09971 17.4125 1.94872 17.2562C1.79772 17.1 1.71289 16.888 1.71289 16.667L1.7129 13.3337C1.7129 13.1127 1.79772 12.9007 1.94872 12.7444L12.4156 1.91107C12.7301 1.58563 13.2398 1.58563 13.5543 1.91107C13.8687 2.23651 13.8687 2.76414 13.5543 3.08958L3.32319 13.6788V15.8337H5.40513L15.6363 5.2444C15.9507 4.91897 16.4604 4.91897 16.7748 5.2444Z"
        fill="#4E5660"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.416 1.91107C12.7305 1.58563 13.2402 1.58563 13.5547 1.91107L16.7752 5.2444C17.0897 5.56984 17.0897 6.09748 16.7752 6.42292C16.4608 6.74835 15.9511 6.74835 15.6367 6.42292L12.416 3.08958C12.1016 2.76414 12.1016 2.23651 12.416 1.91107Z"
        fill="#4E5660"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2211 8.92297L10.0005 5.58965L11.1392 4.41113L14.3597 7.74447L13.2211 8.92297Z"
        fill="#4E5660"
      />
    </svg>
  );
};

EditBlue.propTypes = {
  className: PropTypes.string, // expects a string for additional class name
};
export default EditBlue;
