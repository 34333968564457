import { Formik } from "formik";
import { Heading } from "../../../../../atoms/Heading";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import CustomButton from "../../../../../atoms/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import style from "./index.module.scss";
import { getData, patchData, postData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { useEffect, useState } from "react";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import TagInput from "../../../../../atoms/TagInput";
import MyInput from "../../../../../atoms/MyInput";
import * as Yup from "yup";
import { emailRegex } from "../../../../../../helpers/expirationTime";

const AddInvestor = ({ type }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [investorEditedData, setInvestorEditedData] = useState({});
  const initialValues = {
    investor_name: investorEditedData?.investor_name || "",
    investor_code: investorEditedData?.investor_code || "",
    address: investorEditedData?.address || "",
    master_agreement_date: investorEditedData?.master_agreement_date || "",
    nav_date: investorEditedData?.nav_date || "",
    nav: investorEditedData?.nav || null,
    interest_party_email: investorEditedData?.interest_party_email || "",
  };

  const validationSchema = Yup.object({
    investor_name: Yup.string().required("Investor name is required"),
    investor_code: Yup.string()
      .matches(
        /^[A-Za-z]{3}$/,
        "Investor Code must contain exactly three letters",
      )
      .required("Investor Code is required"),
    address: Yup.string().required("Address is required"),
    master_agreement_date: Yup.string().required(
      "Master agreement is required",
    ),
    nav_date: Yup.string().required("NAV Date is required"),
    nav: Yup.number().required("NAV is required"),
    interest_party_email: Yup.array()
      .of(
        Yup.string()
          .matches(emailRegex, "Invalid email")
          .required("Email is required")
          .email(true),
      )
      .min(1, "At least one Email is required")
      .required("At least one Email is required"),
  });
  const getInvestorData = async () => {
    try {
      const res = await getData({
        endpoint: "investor/getInvestorById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setInvestorEditedData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (id) {
      getInvestorData();
    }
  }, [id]);

  const handleSubmit = async (values) => {
    if (id) {
      try {
        const res = await patchData({
          endpoint: "investor/update",
          token: cookies.t,
          data: values,
          params: {
            id: id,
          },
        });
        if (res) {
          toast.success("Investment Details Updated Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/investor");
        }
      } catch (err) {
        console.log("err", err);
      }
    } else {
      try {
        const res = await postData({
          endpoint: "investor/create",
          token: cookies.t,
          data: values,
        });
        if (res) {
          toast.success("Investment Details Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/investor");
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        key={Math.random}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldTouched,
          isSubmitting,
        }) => (
          <>
            <Breadcrumb
              labels={[
                "Investor",
                `${type === "add" ? "Create" : "Update"} Investment`,
              ]}
            />
            <Heading className="addHeaderStyle">{`${
              type === "add" ? "Create" : "Update"
            } Investment`}</Heading>
            <div
              className="d-flex flex-wrap w-100 "
              style={{ columnGap: "1.36%", rowGap: "16px" }}
            >
              <MyInput
                type="text"
                name="investor_name"
                customholder="Investor Name (Exact Legal Name)"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                InputHeight="small"
                width="23.9%"
              />
              <MyInput
                type="text"
                name="investor_code"
                customholder="Investor code"
                style={{ textTransform: "uppercase" }}
                onBlur={(e) => {
                  handleBlur(e);
                }}
                InputHeight="small"
                width="23.9%"
                info="Uppercase Three Letter Code"
              />
              <MyInput
                type="text"
                name="address"
                customholder="Address"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                InputHeight="small"
                width="49%"
              />
              <div style={{ position: "relative", width: "23.9%" }}>
                <DateSelector
                  placeholder="Master Agreement Date"
                  onChange={async (option) => {
                    setFieldValue("master_agreement_date", option);
                  }}
                  selectedDate={values.master_agreement_date}
                  isError={values?.master_agreement_date}
                  touched={touched.master_agreement_date}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("master_agreement_date", true);
                  }}
                  errors={errors.master_agreement_date}
                />
              </div>
              <div style={{ position: "relative", width: "23.9%" }}>
                <DateSelector
                  placeholder="NAV Date"
                  onChange={async (option) => {
                    setFieldValue("nav_date", option);
                  }}
                  selectedDate={values.nav_date}
                  isError={values?.nav_date}
                  touched={touched.nav_date}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("nav_date", true);
                  }}
                  errors={errors.nav_date}
                />
              </div>
              <MyInput
                type="number"
                name="nav"
                customholder="NAV"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                InputHeight="small"
                width="23.9%"
              />
              <TagInput
                type="text"
                name="interest_party_email"
                customholder="Interested party emails"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                InputHeight="small"
                placeholder="Interested party emails"
                maxTags={5}
                onChange={(tags) => {
                  setFieldValue("interest_party_email", tags);
                }}
                selectedTags={values.interest_party_email}
                tagErrors={
                  Array.isArray(errors?.interest_party_email) &&
                  errors.interest_party_email.length > 0
                    ? errors.interest_party_email
                        .map((value, index) => (value ? index : -1))
                        .filter((index) => index !== -1)
                    : []
                }
                error={errors.interest_party_email}
                styles={{ width: "23.9%" }}
              />

              {/* <TagInput
                  placeholder="Enter tags..."
                  maxTags={5}
                  onChange={() => {}}
                  styles={{ width: '300px' }} // Optional custom styles
                /> */}
            </div>
            <div className={style.buttonWrap}>
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              <div className={style.resetAdd}>
                <CustomButton
                  text="Reset"
                  type="btn-outline-primary"
                  size="btn-lg"
                  handleClick={() => resetForm()}
                />
                <button
                  className="btn-primary btn-lg"
                  type="submit"
                  disabled={!isValid || !dirty || isSubmitting}
                  style={{ width: "100%" }}
                  onClick={() => handleSubmit(values)}
                >
                  {id ? "Update" : "Create"}
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
export default AddInvestor;
