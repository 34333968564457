import styles from './circle_avatar.module.scss';

const CircleAvatar = ({
  name,
  color,
  size,
  fontSize = '10px',
  borderRadius,
  iconColor,
  border,
}) => {
  // Check if the name is empty or undefined, and provide a default value
  let displayName = name || 'New User';

  displayName = displayName?.replace(/\s+/g, ' ').trim();

  // Split the name into first and last name based on whitespace
  const [firstName, lastName] = displayName.split(' ');

  // Handle the case where there's only one word in the name
  const abbreviatedName =
    firstName.length > 1
      ? `${firstName.charAt(0)}${lastName ? lastName.charAt(0) : ''}`
      : firstName;

  const backgroundColor = color || '#83BF6E';

  return (
    <div
      className={styles.circle_avatar}
      style={{
        backgroundColor,
        width: size,
        height: size,
        fontSize,
        borderRadius, // Apply the border radius
        border,
      }}
    >
      <span style={{ fontSize: fontSize, color: iconColor }}>
        {abbreviatedName}
      </span>
    </div>
  );
};

export default CircleAvatar;
