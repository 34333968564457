import { Formik } from "formik";
import { Heading } from "../../../../../atoms/Heading";
import MyDropdown from "../../../../../atoms/MyDropdown";
import {
  CurrencyData,
  documentInternalEnitity,
  SignerStaticData,
  taxAccessData,
  TaxLienData,
  TaxNoticeData,
} from "../../../../../../utils/staticData";
import MyInput from "../../../../../atoms/MyInput";
import style from "./index.module.scss";
import * as Yup from "yup";
import { emailRegex } from "../../../../../../helpers/expirationTime";
import { getData, postData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import MyTextArea from "../../../../../atoms/MyTextArea";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";

const AddLegal = ({ selectedTermSheet, selectedDocuments }) => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const [signerData, setSignerData] = useState([]);
  const [preClient, setPreClient] = useState([]);

  const initialValues = {
    pre_client_name: "",
    pre_client_id: "",
    internal_signer_name: "",
    internal_entity: "",
    external_entity: "",
    external_entity_address: "",
    external_company_number: "",
    Internal_signer_first_name: "",
    internal_signer_last_name: "",
    internal_signer_email: "",
    ex_signer_first_name: "",
    ex_signer_last_name: "",
    ex_signer_email: "",
    company_number: "",
    witness_signer_first_name: "",
    witness_signer_last_name: "",
    witness_signer_email: "",
    internal_witness_signer_first_name: "",
    internal_witness_signer_last_name: "",
    internal_witness_signer_email: "",
    rate_one_month_term: "",
    upfront_fee: "",
    renewal_fee: "",
    currency: "",
    guarantee_limit: "",
    guarantor: "",
    guarantor_address: "",
    guarantor_company_number: "",
    guarnators: "",
    key_persons: "",
    main_book_equity: "",
    tax_notices: "",
    tax_access: "",
    tax_lien: "",
    notes: "",
  };
  const validationSchema = Yup.object().shape({
    internal_signer_name: Yup.string().required("Internal signer is required"),
    internal_entity: Yup.string().required("Internal Entity is required"),
    external_entity: Yup.string().required("Extrernal entity is required"),
    ex_signer_first_name: Yup.string().required("First name is required"),
    ex_signer_last_name: Yup.string().required("Last name is required"),
    ex_signer_email: Yup.string()
      .matches(emailRegex, "Invalid email")
      .required("Email is Required"),
  });
  const getSignerList = async () => {
    try {
      const res = await getData({
        endpoint: "signer/getSignersList",
        token: cookies.t,
      });
      setSignerData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getPreClient = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getDataWithCounterType",
        token: cookies.t,
        params: {
          conterparty_type: "Pre-Client",
        },
      });
      setPreClient(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getSignerList();
    getPreClient();
  }, []);

  const handleSubmit = async (values) => {
    console.log("values", values);
    let payload = {};
    if (selectedDocuments === "Legal") {
      switch (selectedTermSheet) {
        case "Broker Agreement":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              external_entity_first_name: values?.ex_signer_first_name,
              external_entity_last_name: values?.ex_signer_last_name,
              external_entity_email: values?.ex_signer_email,
              external_entity: values?.external_entity,
              internal_entity: values?.internal_entity,
              internal_signer_name: values?.internal_signer_name,
              internal_signer_id: values?.internal_signer_id,
              pre_client_id: values?.pre_client_id,
              pre_client_name: values?.pre_client_name,
            },
          };
          break;
        case "Supplier Credit Agreement(US)":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              external_entity_first_name: values?.ex_signer_first_name,
              external_entity_last_name: values?.ex_signer_last_name,
              external_entity_email: values?.ex_signer_email,
              internal_signer_id: values?.internal_signer_id,
              internal_signer_name: values?.internal_signer_name,
              pre_client_id: values?.pre_client_id,
              pre_client_name: values?.pre_client_name,
              external_entity: values?.external_entity,
              internal_entity: values?.internal_entity,
              company_number: values?.company_number,
              currency: values?.currency,
              guarnators: values?.guarnators,
              key_persons: values?.key_persons,
              main_book_equity: values?.main_book_equity,
              tax_notices: values?.tax_access,
              tax_access: values?.tax_access,
              tax_lien: values?.tax_lien,
              notes: values?.notes,
            },
          };
          break;
        case "Supplier Credit Agreement(UK)":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              pre_client_id: values?.pre_client_id,
              pre_client_name: values?.pre_client_name,
              internal_signer_id: values?.internal_signer_id,
              internal_signer_name: values?.internal_signer_name,
              external_entity: values?.external_entity,
              internal_entity: values?.internal_entity,
              external_entity_first_name: values?.ex_signer_first_name,
              external_entity_last_name: values?.ex_signer_last_name,
              external_entity_email: values?.ex_signer_email,
              company_number: values?.company_number,
              witness_signer_first_name: values?.witness_signer_first_name,
              witness_signer_last_name: values?.witness_signer_last_name,
              witness_signer_email: values?.witness_signer_email,
              currency: values?.currency,
              guarnators: values?.guarnators,
              key_persons: values?.key_persons,
              main_book_equity: values?.main_book_equity,
              tax_notices: values?.tax_access,
              tax_access: values?.tax_access,
              tax_lien: values?.tax_lien,
              notes: values?.notes,
            },
          };
          break;
        case "Supplier Credit Guarantee(US Corp + Personal)":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              pre_client_id: values?.pre_client_id,
              pre_client_name: values?.pre_client_name,
              internal_signer_id: values?.internal_signer_id,
              internal_signer_name: values?.internal_signer_name,
              external_entity: values?.external_entity,
              internal_entity: values?.internal_entity,
              external_entity_address: values?.external_entity_address,
              external_company_number: values?.external_company_number,
              external_entity_first_name: values?.ex_signer_first_name,
              external_entity_last_name: values?.ex_signer_last_name,
              external_entity_email: values?.ex_signer_email,
              currency: values?.currency,
              guarantee_limit: values?.guarantee_limit,
              guarantor: values?.guarantor,
              guarantor_address: values?.guarantor_address,
              guarantor_company_number: values?.guarantor_company_number,
              notes: values?.notes,
            },
          };
          break;
        case "Supplier Credit Secured Guarantee (US Corp)":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              pre_client_id: values?.pre_client_id,
              pre_client_name: values?.pre_client_name,
              internal_signer_id: values?.internal_signer_id,
              internal_signer_name: values?.internal_signer_name,
              external_entity: values?.external_entity,
              internal_entity: values?.internal_entity,
              external_entity_address: values?.external_entity_address,
              external_company_number: values?.external_company_number,
              external_entity_first_name: values?.ex_signer_first_name,
              external_entity_last_name: values?.ex_signer_last_name,
              external_entity_email: values?.ex_signer_email,
              currency: values?.currency,
              guarantee_limit: values?.guarantee_limit,
              guarantor: values?.guarantor,
              guarantor_address: values?.guarantor_address,
              guarantor_company_number: values?.guarantor_company_number,
              notes: values?.notes,
            },
          };
          break;
        case "Supplier Credit Guarantee (UK Personal)":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              pre_client_id: values?.pre_client_id,
              pre_client_name: values?.pre_client_name,
              internal_signer_id: values?.internal_signer_id,
              internal_signer_name: values?.internal_signer_name,
              external_entity: values?.external_entity,
              internal_entity: values?.internal_entity,
              external_entity_address: values?.external_entity_address,
              external_company_number: values?.external_company_number,
              external_entity_first_name: values?.ex_signer_first_name,
              external_entity_last_name: values?.ex_signer_last_name,
              external_entity_email: values?.ex_signer_email,
              witness_signer_first_name: values?.witness_signer_first_name,
              witness_signer_last_name: values?.witness_signer_last_name,
              witness_signer_email: values?.witness_signer_email,
              currency: values?.currency,
              guarantee_limit: values?.guarantee_limit,
              guarantor: values?.guarantor,
              guarantor_address: values?.guarantor_address,
              guarantor_company_number: values?.guarantor_company_number,
              notes: values?.notes,
            },
          };
          break;
        case "Supplier Credit Guarantee (UK Corp)":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              pre_client_id: values?.pre_client_id,
              pre_client_name: values?.pre_client_name,
              internal_signer_id: values?.internal_signer_id,
              internal_signer_name: values?.internal_signer_name,
              external_entity: values?.external_entity,
              internal_entity: values?.internal_entity,
              external_entity_address: values?.external_entity_address,
              external_company_number: values?.external_company_number,
              external_entity_first_name: values?.ex_signer_first_name,
              external_entity_last_name: values?.ex_signer_last_name,
              external_entity_email: values?.ex_signer_email,
              witness_signer_first_name: values?.witness_signer_first_name,
              witness_signer_last_name: values?.witness_signer_last_name,
              witness_signer_email: values?.witness_signer_email,
              currency: values?.currency,
              guarantee_limit: values?.guarantee_limit,
              guarantor: values?.guarantor,
              guarantor_address: values?.guarantor_address,
              guarantor_company_number: values?.guarantor_company_number,
              notes: values?.notes,
            },
          };
          break;
        case "Supplier Credit Side Letter(US)":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              pre_client_id: values?.pre_client_id,
              pre_client_name: values?.pre_client_name,
              internal_signer_id: values?.internal_signer_id,
              internal_signer_name: values?.internal_signer_name,
              external_entity: values?.external_entity,
              internal_entity: values?.internal_entity,
              external_entity_address: values?.external_entity_address,
              external_entity_first_name: values?.ex_signer_first_name,
              external_entity_last_name: values?.ex_signer_last_name,
              external_entity_email: values?.ex_signer_email,
              rate_one_month_term: values?.rate_one_month_term,
              upfront_fee: values?.upfront_fee,
              renewal_fee: values?.renewal_fee,
              notes: values?.notes,
            },
          };
          break;
        case "Supplier Credit Side Letter(UK)":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              pre_client_id: values?.pre_client_id,
              pre_client_name: values?.pre_client_name,
              internal_signer_id: values?.internal_signer_id,
              internal_signer_name: values?.internal_signer_name,
              external_entity: values?.external_entity,
              internal_entity: values?.internal_entity,
              external_entity_address: values?.external_entity_address,
              external_entity_first_name: values?.ex_signer_first_name,
              external_entity_last_name: values?.ex_signer_last_name,
              external_entity_email: values?.ex_signer_email,
              rate_one_month_term: values?.rate_one_month_term,
              upfront_fee: values?.upfront_fee,
              renewal_fee: values?.renewal_fee,
              notes: values?.notes,
            },
          };
          break;
        case "Electornic Signature Agreement(US)":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              pre_client_id: values?.pre_client_id,
              pre_client_name: values?.pre_client_name,
              internal_signer_id: values?.internal_signer_id,
              internal_signer_name: values?.internal_signer_name,
              external_entity: values?.external_entity,
              internal_entity: values?.internal_entity,
              external_entity_address: values?.external_entity_address,
              external_entity_first_name: values?.ex_signer_first_name,
              external_entity_last_name: values?.ex_signer_last_name,
              external_entity_email: values?.ex_signer_email,
              rate_one_month_term: values?.rate_one_month_term,
              upfront_fee: values?.upfront_fee,
              renewal_fee: values?.renewal_fee,
              notes: values?.notes,
            },
          };
          break;
        case "Electornic Signature Agreement(UK)":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              pre_client_id: values?.pre_client_id,
              pre_client_name: values?.pre_client_name,
              internal_signer_id: values?.internal_signer_id,
              internal_signer_name: values?.internal_signer_name,
              external_entity: values?.external_entity,
              internal_entity: values?.internal_entity,
              external_entity_address: values?.external_entity_address,
              external_entity_first_name: values?.ex_signer_first_name,
              external_entity_last_name: values?.ex_signer_last_name,
              external_entity_email: values?.ex_signer_email,
              rate_one_month_term: values?.rate_one_month_term,
              upfront_fee: values?.upfront_fee,
              renewal_fee: values?.renewal_fee,
              notes: values?.notes,
            },
          };
          break;
        case "Debenture-Second Ranking SC(UK)":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              pre_client_id: values?.pre_client_id,
              pre_client_name: values?.pre_client_name,
              internal_signer_id: values?.internal_signer_id,
              internal_signer_name: values?.internal_signer_name,
              external_entity: values?.external_entity,
              internal_entity: values?.internal_entity,
              external_entity_address: values?.external_entity_address,
              external_company_number: values?.external_company_number,
              internal_witness_signer_first_name:
                values?.internal_witness_signer_first_name,
              internal_witness_signer_last_name:
                values?.internal_witness_signer_last_name,
              internal_witness_signer_email:
                values?.internal_witness_signer_email,
              external_entity_first_name: values?.ex_signer_first_name,
              external_entity_last_name: values?.ex_signer_last_name,
              external_entity_email: values?.ex_signer_email,
              witness_signer_first_name: values?.witness_signer_first_name,
              witness_signer_last_name: values?.witness_signer_last_name,
              witness_signer_email: values?.witness_signer_email,
            },
          };
          break;
        default:
          break;
      }
    }
    try {
      const res = await postData({
        endpoint: "documents/create/documents",
        token: cookies.t,
        data: payload,
      });
      if (res) {
        toast.success("Documents Created Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        navigate("/dashboard/tools");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          values,
          touched,
          errors,
          dirty,
          setFieldValue,
          setValues,
          setFieldTouched,
          handleBlur,
          handleSubmit,
          isValid,
          isSubmitting,
        }) => {
          return (
            <>
              <div className="mt-4">
                <MyDropdown
                  placeholder="Client and Pre-Client Name"
                  data={preClient}
                  onChange={(option) => {
                    setFieldValue("pre_client_name", option?.label);
                    setFieldValue("pre_client_id", option?.value);
                  }}
                  format="all"
                  touched={touched.pre_client_name}
                  selectedDate={values.pre_client_name}
                  errors={errors.pre_client_name}
                  handleBlur={(e) => {
                    setFieldTouched("pre_client_name", true);
                    handleBlur(e);
                  }}
                  width="32.426%"
                />
              </div>
              <div className="mt-4">
                <Heading className="HeadingStyle">Signer Selection</Heading>
                <div
                  className="d-flex flex-wrap"
                  style={{ columnGap: "1.36%", rowGap: "16px" }}
                >
                  <MyDropdown
                    placeholder="Internal Singer"
                    data={SignerStaticData}
                    onChange={(option) => {
                      setFieldValue("internal_signer_name", option?.label);
                      setFieldValue("internal_signer_id", option?.value);
                    }}
                    format="all"
                    touched={touched.internal_signer_name}
                    selectedDate={values.internal_signer_name}
                    errors={errors.internal_signer_name}
                    handleBlur={(e) => {
                      setFieldTouched("internal_signer_name", true);
                      handleBlur(e);
                    }}
                    width="32.426%"
                  />
                  <MyDropdown
                    placeholder="Internal Entity"
                    data={documentInternalEnitity}
                    onChange={(option) => {
                      setFieldValue("internal_entity", option);
                    }}
                    touched={touched.internal_entity}
                    selectedDate={values.internal_entity}
                    errors={errors.internal_entity}
                    handleBlur={(e) => {
                      setFieldTouched("internal_entity", true);
                      handleBlur(e);
                    }}
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="external_entity"
                    customholder="External Entity"
                    width="32.426%"
                  />
                  {(selectedTermSheet ===
                    "Supplier Credit Guarantee(US Corp + Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Secured Guarantee (US Corp)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Corp)" ||
                    selectedTermSheet === "Supplier Credit Side Letter(US)" ||
                    selectedTermSheet === "Supplier Credit Side Letter(UK)" ||
                    selectedTermSheet ===
                      "Electornic Signature Agreement(US)" ||
                    selectedTermSheet ===
                      "Electornic Signature Agreement(UK)" ||
                    selectedTermSheet ===
                      "Debenture-Second Ranking SC(UK)") && (
                    <MyInput
                      type="text"
                      name="external_entity_address"
                      customholder="External Entity Address"
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet ===
                    "Supplier Credit Guarantee(US Corp + Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Secured Guarantee (US Corp)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Corp)" ||
                    selectedTermSheet ===
                      "Debenture-Second Ranking SC(UK)") && (
                    <MyInput
                      type="text"
                      name="external_company_number"
                      customholder="External Company Number"
                      width="32.426%"
                      info={"N/A or 1234567"}
                    />
                  )}
                  {selectedTermSheet === "Debenture-Second Ranking SC(UK)" && (
                    <MyInput
                      type="text"
                      name="Internal_signer_first_name"
                      customholder="Internal Witness Signer First Name"
                      width="32.426%"
                      info={"N/A if US, required if UK"}
                    />
                  )}
                  {selectedTermSheet === "Debenture-Second Ranking SC(UK)" && (
                    <MyInput
                      type="text"
                      name="internal_signer_last_name"
                      customholder="Internal Witness Signer last Name"
                      width="32.426%"
                      info={"N/A if US, required if UK"}
                    />
                  )}
                  {selectedTermSheet === "Debenture-Second Ranking SC(UK)" && (
                    <MyInput
                      type="text"
                      name="internal_signer_email"
                      customholder="Internal Witness Signer Email"
                      width="32.426%"
                      info={"N/A if US, required if UK"}
                    />
                  )}
                  <MyInput
                    type="text"
                    name="ex_signer_first_name"
                    customholder="External Signer First Name"
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="ex_signer_last_name"
                    customholder="External Signer Last Name"
                    width="32.426%"
                  />
                  <MyInput
                    type="text"
                    name="ex_signer_email"
                    customholder="External Signer Email"
                    width="32.426%"
                  />
                  {(selectedTermSheet === "Supplier Credit Agreement(US)" ||
                    selectedTermSheet === "Supplier Credit Agreement(UK)") && (
                    <MyInput
                      type="text"
                      name="company_number"
                      customholder="Company Number(UK only)"
                      width="32.426%"
                      info="N/A or 12345678"
                    />
                  )}
                  {(selectedTermSheet === "Supplier Credit Agreement(UK)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Corp)" ||
                    selectedTermSheet ===
                      "Debenture-Second Ranking SC(UK)") && (
                    <MyInput
                      type="text"
                      name="witness_signer_first_name"
                      customholder="Witness Signer First Name"
                      width="32.426%"
                      info={"N/A if US, required if UK"}
                    />
                  )}
                  {(selectedTermSheet === "Supplier Credit Agreement(UK)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Corp)" ||
                    selectedTermSheet ===
                      "Debenture-Second Ranking SC(UK)") && (
                    <MyInput
                      type="text"
                      name="witness_signer_last_name"
                      customholder="Witness Signer Last Name"
                      width="32.426%"
                      info={"N/A if US, required if UK"}
                    />
                  )}
                  {(selectedTermSheet === "Supplier Credit Agreement(UK)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Corp)" ||
                    selectedTermSheet ===
                      "Debenture-Second Ranking SC(UK)") && (
                    <MyInput
                      type="text"
                      name="witness_signer_email"
                      customholder="Witness Signer Email"
                      width="32.426%"
                      info={"N/A if US, required if UK"}
                    />
                  )}
                </div>
              </div>
              <div className="mt-4">
                {(selectedTermSheet === "Supplier Credit Agreement(US)" ||
                  selectedTermSheet === "Supplier Credit Agreement(UK)" ||
                  selectedTermSheet ===
                    "Supplier Credit Guarantee(US Corp + Personal)" ||
                  selectedTermSheet ===
                    "Supplier Credit Secured Guarantee (US Corp)" ||
                  selectedTermSheet ===
                    "Supplier Credit Guarantee (UK Personal)" ||
                  selectedTermSheet === "Supplier Credit Guarantee (UK Corp)" ||
                  selectedTermSheet === "Supplier Credit Side Letter(US)" ||
                  selectedTermSheet === "Supplier Credit Side Letter(UK)" ||
                  selectedTermSheet === "Electornic Signature Agreement(US)" ||
                  selectedTermSheet === "Electornic Signature Agreement(UK)" ||
                  selectedTermSheet === "Debenture-Second Ranking SC(UK)") && (
                  <Heading className="HeadingStyle">Document Variables</Heading>
                )}
                <div
                  className="d-flex flex-wrap"
                  style={{ columnGap: "1.36%", rowGap: "16px" }}
                >
                  {(selectedTermSheet === "Supplier Credit Side Letter(US)" ||
                    selectedTermSheet === "Supplier Credit Side Letter(UK)" ||
                    selectedTermSheet ===
                      "Electornic Signature Agreement(US)" ||
                    selectedTermSheet ===
                      "Electornic Signature Agreement(UK)") && (
                    <MyInput
                      type="text"
                      name="rate_one_month_term"
                      info="Rate (XX% or XXX% per annum, plus one-month Term SOFR, caluculated as set forth in Schedule A):"
                      customholder="Rate"
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet === "Supplier Credit Side Letter(US)" ||
                    selectedTermSheet === "Supplier Credit Side Letter(UK)" ||
                    selectedTermSheet ===
                      "Electornic Signature Agreement(US)" ||
                    selectedTermSheet ===
                      "Electornic Signature Agreement(UK)") && (
                    <MyInput
                      type="text"
                      name="upfront_fee"
                      customholder="Upfront Fee"
                      width="32.426%"
                      info="2% / Waived"
                    />
                  )}
                  {(selectedTermSheet === "Supplier Credit Side Letter(US)" ||
                    selectedTermSheet === "Supplier Credit Side Letter(UK)" ||
                    selectedTermSheet ===
                      "Electornic Signature Agreement(US)" ||
                    selectedTermSheet ===
                      "Electornic Signature Agreement(UK)") && (
                    <MyInput
                      type="text"
                      name="renewal_fee"
                      customholder="Renewal Fee"
                      width="32.426%"
                      info="2% / Waived"
                    />
                  )}
                  {(selectedTermSheet === "Supplier Credit Agreement(US)" ||
                    selectedTermSheet === "Supplier Credit Agreement(UK)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee(US Corp + Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Secured Guarantee (US Corp)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Corp)") && (
                    <MyDropdown
                      placeholder="Currency"
                      data={CurrencyData}
                      onChange={(option) => {
                        setFieldValue("currency", option);
                      }}
                      touched={touched.currency}
                      selectedDate={values.currency}
                      errors={errors.currency}
                      handleBlur={(e) => {
                        setFieldTouched("currency", true);
                        handleBlur(e);
                      }}
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet ===
                    "Supplier Credit Guarantee(US Corp + Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Secured Guarantee (US Corp)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Corp)") && (
                    <MyInput
                      type="text"
                      name="guarantee_limit"
                      customholder="Guarantee Limit"
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet ===
                    "Supplier Credit Guarantee(US Corp + Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Secured Guarantee (US Corp)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Corp)") && (
                    <MyInput
                      type="text"
                      name="guarantor"
                      customholder="Guarantor"
                      width="32.426%"
                      info="John Smith or ABC Company, LLC"
                    />
                  )}
                  {(selectedTermSheet ===
                    "Supplier Credit Guarantee(US Corp + Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Secured Guarantee (US Corp)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Corp)") && (
                    <MyInput
                      type="text"
                      name="guarantor_address"
                      customholder="Guarantor Address"
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet ===
                    "Supplier Credit Guarantee(US Corp + Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Secured Guarantee (US Corp)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Corp)") && (
                    <MyInput
                      type="text"
                      name="guarantor_company_number"
                      customholder="Guarantor Company Number"
                      width="32.426%"
                      info="N/A or 1234567"
                    />
                  )}
                  {(selectedTermSheet === "Supplier Credit Agreement(US)" ||
                    selectedTermSheet === "Supplier Credit Agreement(UK)") && (
                    <MyInput
                      type="text"
                      name="guarnators"
                      customholder="Guarantors(List PG and CG)"
                      width="32.426%"
                      info="ABC Company, John Smith, John Doe"
                    />
                  )}
                  {(selectedTermSheet === "Supplier Credit Agreement(US)" ||
                    selectedTermSheet === "Supplier Credit Agreement(UK)") && (
                    <MyInput
                      type="text"
                      name="key_persons"
                      customholder="Key Persons"
                      width="32.426%"
                      info="John Smith, John Doe"
                    />
                  )}
                  {(selectedTermSheet === "Supplier Credit Agreement(US)" ||
                    selectedTermSheet === "Supplier Credit Agreement(UK)") && (
                    <MyInput
                      type="text"
                      name="main_book_equity"
                      customholder="Min Book Equity"
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet === "Supplier Credit Agreement(US)" ||
                    selectedTermSheet === "Supplier Credit Agreement(UK)") && (
                    <MyDropdown
                      placeholder="Tax Notices"
                      data={TaxNoticeData}
                      onChange={(option) => {
                        setFieldValue("tax_notices", option);
                      }}
                      touched={touched.tax_notices}
                      selectedDate={values.tax_notices}
                      errors={errors.tax_notices}
                      handleBlur={(e) => {
                        setFieldTouched("tax_notices", true);
                        handleBlur(e);
                      }}
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet === "Supplier Credit Agreement(US)" ||
                    selectedTermSheet === "Supplier Credit Agreement(UK)") && (
                    <MyDropdown
                      placeholder="Tax Access"
                      data={taxAccessData}
                      onChange={(option) => {
                        setFieldValue("tax_access", option);
                      }}
                      touched={touched.tax_access}
                      selectedDate={values.tax_access}
                      errors={errors.tax_access}
                      handleBlur={(e) => {
                        setFieldTouched("tax_access", true);
                        handleBlur(e);
                      }}
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet === "Supplier Credit Agreement(US)" ||
                    selectedTermSheet === "Supplier Credit Agreement(UK)") && (
                    <MyDropdown
                      placeholder="Tax Lien"
                      data={TaxLienData}
                      onChange={(option) => {
                        setFieldValue("tax_lien", option);
                      }}
                      touched={touched.tax_lien}
                      selectedDate={values.tax_lien}
                      errors={errors.tax_lien}
                      handleBlur={(e) => {
                        setFieldTouched("tax_lien", true);
                        handleBlur(e);
                      }}
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet === "Supplier Credit Agreement(US)" ||
                    selectedTermSheet === "Supplier Credit Agreement(UK)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee(US Corp + Personal)" ||
                    selectedTermSheet ===
                      "Supplier Credit Secured Guarantee (US Corp)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Personal)" ||
                    selectedTermSheet === "Supplier Credit Side Letter(US)" ||
                    selectedTermSheet === "Supplier Credit Side Letter(UK)" ||
                    selectedTermSheet ===
                      "Electornic Signature Agreement(US)" ||
                    selectedTermSheet ===
                      "Electornic Signature Agreement(UK)" ||
                    selectedTermSheet ===
                      "Supplier Credit Guarantee (UK Corp)") && (
                    <MyTextArea
                      as="textarea"
                      customholder={"Other Notes and Adjustments:"}
                      name="notes"
                      style={{ width: "32.426%" }}
                      info={
                        selectedTermSheet === "Supplier Credit Side Letter(US)"
                          ? "Intentionally Left Blank or...."
                          : selectedTermSheet ===
                            "Supplier Credit Guarantee(US Corp + Personal)"
                          ? "TERM ABC is adjusted to...."
                          : "Intentionally Left Blank or Term ABC adjusted to...."
                      }
                    />
                  )}
                  {selectedTermSheet === "Debenture-Second Ranking SC(UK)" && (
                    <MyTextArea
                      as="textarea"
                      customholder="Permitted Security TERM ABC is adjusted to ..."
                      name="notes"
                      style={{ width: "32.426%" }}
                    />
                  )}
                </div>
              </div>
              {/* <div className="mt-4">
                {(selectedTermSheet === "Supplier Credit Agreement(US)" ||
                  selectedTermSheet === "Supplier Credit Agreement(UK)" ||
                  selectedTermSheet ===
                    "Supplier Credit Guarantee(US Corp + Personal)" ||
                  selectedTermSheet ===
                    "Supplier Credit Secured Guarantee (US Corp)" ||
                  selectedTermSheet ===
                    "Supplier Credit Guarantee (UK Personal)" ||
                  selectedTermSheet === "Supplier Credit Guarantee (UK Corp)" ||
                  selectedTermSheet === "Supplier Credit Side Letter(US)" ||
                  selectedTermSheet === "Supplier Credit Side Letter(UK)" ||
                  selectedTermSheet ===
                    "Electornic Signature Agreement(US)" ||
                  selectedTermSheet ===
                    "Electornic Signature Agreement(UK)" ||
                  selectedTermSheet === "Debenture-Second Ranking SC(UK)") && (
                  <Heading className="HeadingStyle">Document Variables</Heading>
                )}
                <div
                  className="d-flex flex-wrap"
                  style={{ columnGap: "1.36%", rowGap: "16px" }}
                > */}

              {/* </div>
              </div> */}
              <div className={style.buttonWrap}>
                <button
                  className="btn btn-primary w-30 mt-2 btn-xl"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => {
                    handleSubmit(values);
                  }}
                >
                  Generate Document
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default AddLegal;
