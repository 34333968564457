const LockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_93_267)">
        <path
          d="M15.8333 7.02V5.83333C15.8333 4.28623 15.2187 2.8025 14.1247 1.70854C13.0308 0.614578 11.547 -3.8147e-06 9.99993 -3.8147e-06C8.45283 -3.8147e-06 6.9691 0.614578 5.87514 1.70854C4.78118 2.8025 4.1666 4.28623 4.1666 5.83333V7.02C3.4244 7.34392 2.79267 7.8771 2.34868 8.55434C1.90469 9.23158 1.66766 10.0235 1.6666 10.8333V15.8333C1.66792 16.938 2.10733 17.997 2.88845 18.7781C3.66956 19.5593 4.7286 19.9987 5.83326 20H14.1666C15.2713 19.9987 16.3303 19.5593 17.1114 18.7781C17.8925 17.997 18.3319 16.938 18.3333 15.8333V10.8333C18.3322 10.0235 18.0952 9.23158 17.6512 8.55434C17.2072 7.8771 16.5755 7.34392 15.8333 7.02ZM5.83326 5.83333C5.83326 4.72826 6.27225 3.66845 7.05365 2.88705C7.83505 2.10565 8.89486 1.66666 9.99993 1.66666C11.105 1.66666 12.1648 2.10565 12.9462 2.88705C13.7276 3.66845 14.1666 4.72826 14.1666 5.83333V6.66666H5.83326V5.83333ZM16.6666 15.8333C16.6666 16.4964 16.4032 17.1323 15.9344 17.6011C15.4655 18.0699 14.8296 18.3333 14.1666 18.3333H5.83326C5.17022 18.3333 4.53434 18.0699 4.0655 17.6011C3.59666 17.1323 3.33326 16.4964 3.33326 15.8333V10.8333C3.33326 10.1703 3.59666 9.5344 4.0655 9.06556C4.53434 8.59672 5.17022 8.33333 5.83326 8.33333H14.1666C14.8296 8.33333 15.4655 8.59672 15.9344 9.06556C16.4032 9.5344 16.6666 10.1703 16.6666 10.8333V15.8333Z"
          fill="#3B424A"
        />
        <path
          d="M9.99995 11.6664C9.77893 11.6664 9.56697 11.7542 9.41069 11.9105C9.2544 12.0668 9.16661 12.2787 9.16661 12.4997V14.1664C9.16661 14.3874 9.2544 14.5994 9.41069 14.7557C9.56697 14.9119 9.77893 14.9997 9.99995 14.9997C10.221 14.9997 10.4329 14.9119 10.5892 14.7557C10.7455 14.5994 10.8333 14.3874 10.8333 14.1664V12.4997C10.8333 12.2787 10.7455 12.0668 10.5892 11.9105C10.4329 11.7542 10.221 11.6664 9.99995 11.6664Z"
          fill="#3B424A"
        />
      </g>
      <defs>
        <clipPath id="clip0_93_267">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default LockIcon;
