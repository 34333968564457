import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import '@mantine/core/styles.css';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '@mantine/core/styles.css';
import Errorboundary from './services/errorBoundarry';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
      <Provider store={store}>
        <Errorboundary fallback="Something Went Wrong">
         <App/>
         </Errorboundary>
      </Provider>
    </CookiesProvider>
);
