import { useState } from "react";
import {
  DocumentsTools,
  ExpenseTermsData,
  formtermsData,
  legalData,
  NDATypeData,
  TermSheetTypeData,
} from "../../../../../utils/staticData";
import { Heading } from "../../../../atoms/Heading";
import MyDropdown from "../../../../atoms/MyDropdown";
import AddNDA from "../Documents/addNDA";
import SupplierCreditTerm from "./AddTermSheet/supplierCredit";
import AddLegal from "./addLegal";
import AddForms from "./addForms";
import AddExpense from "./addExpense";

const Documents = () => {
  const [selectedDocuments, setSelectedDocuments] = useState();
  const [selectedTermSheet, setSelectedTermSheet] = useState();
  const [termsheetData, setTermSheetData] = useState();
  const handleSelectedDocuments = (value) => {
    setSelectedDocuments(value);
    switch (value) {
      case "NDA":
        setTermSheetData(NDATypeData);
        break;
      case "Term sheet":
        setTermSheetData(TermSheetTypeData);
        break;
      case "Legal":
        setTermSheetData(legalData);
        break;
      case "Forms":
        setTermSheetData(formtermsData);
        break;
      case "Expense":
        setTermSheetData(ExpenseTermsData);
        break;
      default:
        break;
    }
  };

  console.log("selectedTermSheet", selectedTermSheet);
  return (
    <>
      <Heading className="addHeaderStyle">Documents</Heading>
      <div
        className="d-flex flex-wrap"
        style={{ columnGap: "1.36%", rowGap: "16px", marginTop: "20px" }}
      >
        <MyDropdown
          placeholder="Select Document"
          data={DocumentsTools}
          onChange={(option) => {
            handleSelectedDocuments(option);
          }}
          selectedValue={selectedDocuments}
          width="32.426%"
        />
        <MyDropdown
          placeholder="Document Type"
          data={termsheetData}
          onChange={(option) => {
            setSelectedTermSheet(option);
          }}
          width="32.426%"
        />
      </div>
      {selectedDocuments === "NDA" &&
        (selectedTermSheet === "General Mutual NDA" ||
          selectedTermSheet === "Company NDA") && (
          <AddNDA
            selectedTermSheet={selectedTermSheet}
            selectedDocuments={selectedDocuments}
          />
        )}

      {selectedDocuments === "Term sheet" &&
        (selectedTermSheet === "Supplier Credit Term Sheet" ||
          selectedTermSheet === "Accounts Receivable Line Term Sheet" ||
          selectedTermSheet === "Account Receivable + Inventory Term Sheet" ||
          selectedTermSheet === "Factoring Term Sheet" ||
          selectedTermSheet === "Confidential Invoice Discounting Term Sheet" ||
          selectedTermSheet === "Promissory Note Term Sheet") && (
          <SupplierCreditTerm
            selectedTermSheet={selectedTermSheet}
            selectedDocuments={selectedDocuments}
          />
        )}
      {selectedDocuments === "Legal" &&
        (selectedTermSheet === "Broker Agreement" ||
          selectedTermSheet === "Supplier Credit Agreement(US)" ||
          selectedTermSheet === "Supplier Credit Agreement(UK)" ||
          selectedTermSheet ===
            "Supplier Credit Guarantee(US Corp + Personal)" ||
          selectedTermSheet === "Supplier Credit Secured Guarantee (US Corp)" ||
          selectedTermSheet === "Supplier Credit Guarantee (UK Personal)" ||
          selectedTermSheet === "Supplier Credit Guarantee (UK Corp)" ||
          selectedTermSheet === "Supplier Credit Side Letter(US)" ||
          selectedTermSheet === "Supplier Credit Side Letter(UK)" ||
          selectedTermSheet === "Electornic Signature Agreement(US)" ||
          selectedTermSheet === "Electornic Signature Agreement(UK)" ||
          selectedTermSheet === "Debenture-Second Ranking SC(UK)") && (
          <AddLegal
            selectedDocuments={selectedDocuments}
            selectedTermSheet={selectedTermSheet}
          />
        )}
      {selectedDocuments === "Forms" &&
        (selectedTermSheet === "Credit Application Form" ||
          selectedTermSheet === "Personal Financial Statement" ||
          selectedTermSheet === "Personal Financial Statement" ||
          selectedTermSheet === "Catalyst Release Form" ||
          selectedTermSheet === "Trade Reference Form(Full)" ||
          selectedTermSheet === "Trade Reference Form(No Doc Request)" ||
          selectedTermSheet === "Supplier Contact List" ||
          selectedTermSheet === "Customer Contact List" ||
          selectedTermSheet === "Debt Schedule") && (
          <AddForms
            selectedDocuments={selectedDocuments}
            selectedTermSheet={selectedTermSheet}
          />
        )}
      {selectedDocuments === "Expense" &&
        (selectedTermSheet === "Expense Approval Form" ||
          selectedTermSheet === "Expense Approval Form (All Funds)") && (
          <AddExpense
            selectedDocuments={selectedDocuments}
            selectedTermSheet={selectedTermSheet}
          />
        )}
    </>
  );
};
export default Documents;
