import React from 'react';
import FormHeading from '../../../atoms/FormHeading';
import Form from './Form';

const ForgotPassword = () => {
  return (
    <>
      <FormHeading
        title="Forgot Password"
        description="Enter the email address you used when you joined, and we will send you a password reset link."
      />
      <Form />
    </>
  );
};

export default ForgotPassword;
