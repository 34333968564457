const ReportsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clip-path="url(#clip0_699_4413)">
        <path
          d="M8.25027 17.9775C6.0682 17.9775 3.9755 17.1106 2.43254 15.5677C0.889589 14.0247 0.0227661 11.932 0.0227661 9.74996C0.0227661 7.56789 0.889589 5.4752 2.43254 3.93224C3.9755 2.38928 6.0682 1.52246 8.25027 1.52246H9.00027L9.01002 2.27246L9.00027 8.99996H16.4778V9.74996C16.4754 11.9313 15.6078 14.0226 14.0654 15.565C12.5229 17.1075 10.4316 17.9751 8.25027 17.9775ZM7.50927 3.06371C6.24999 3.20339 5.05576 3.69583 4.06403 4.48435C3.07231 5.27288 2.32341 6.32542 1.90355 7.52083C1.48369 8.71624 1.40993 10.0059 1.69077 11.2414C1.97161 12.4769 2.59562 13.6079 3.49098 14.5044C4.38634 15.4008 5.51665 16.0262 6.75179 16.3085C7.98693 16.5909 9.27669 16.5187 10.4726 16.1003C11.6685 15.6819 12.722 14.9342 13.5117 13.9435C14.3014 12.9527 14.7953 11.7591 14.9365 10.5H7.50027L7.50927 3.06371Z"
          fill="white"
        />
        <path
          d="M12.014 1.71589C13.0443 1.98542 13.9842 2.52428 14.7373 3.27715C15.4905 4.03001 16.0296 4.96976 16.2995 5.99989H12.014V1.71589ZM10.514 0.0253906V7.49989H17.99C17.9804 5.52025 17.1896 3.62446 15.7896 2.22477C14.3896 0.825075 12.4937 0.0346575 10.514 0.0253906Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_699_4413">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ReportsIcon;
