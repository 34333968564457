import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Form from "./Form";
import { Heading } from "../../../atoms/Heading";

const Verification = () => {
  const navigate = useNavigate();
  // Back btn Handling ---------------------------------------------------------------------------------------------
  const onButtonEvent = (e) => {
    e.preventDefault();
    navigate("/");
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", onButtonEvent);
    return () => {
      window.removeEventListener("popstate", onButtonEvent);
    };
  }, []);

  return (
    <div>

      <Heading className="h2">OTP</Heading>
      <div className="fs-16 fw-4 mb-2">
      Enter your verification code we sent you on your email.
      </div>
      <Form />
    </div>
  );
};

export default Verification;
