import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getData } from "../../../../../../services";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import { Heading } from "../../../../../atoms/Heading";
import CustomButton from "../../../../../atoms/CustomButton";
import InputLayout from "../../../../../atoms/InputLayout";
import "./styles.scss";
const ReportingRequirement = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [initialData, setInitialData] = useState({});

  const ViewInfoField = ({ label, value }) => (
    <div className="col-3">
      <label className="view-label">{label}</label>
      <p className="view-data">{value}</p>
    </div>
  );

  useEffect(() => {
    (async () => {
      const res = await getData({
        endpoint: "/covenant/getCovenantById",
        params: { id: id },
        token: cookies.t,
      });
      setInitialData(res.data);
    })();
  }, [id]);

  console.log("ini", initialData);
  return (
    <>
      <Breadcrumb
        labels={["Covenant", "Reporting Requirement"]}
        excludeLastPath={true}
      />
      <div className="headingSearchWrap">
        <div>
          <Heading className="HeadingStyle">Reporting Requirement</Heading>
        </div>
        <div className="searchButton">
          <CustomButton
            text="Create New Reporting Requirement"
            type="btn-primary"
            handleClick={() => {
              navigate("add");
            }}
          />
        </div>
      </div>

      <div className="view-info-container">
        <div className="row">
          <ViewInfoField label="Client Name" value={initialData?.client_name} />
          <ViewInfoField label="Covenant Name" value={initialData?.name} />
          <ViewInfoField
            label="Covenant Description"
            value={initialData?.long_description}
          />
          <ViewInfoField label="Frequency" value={initialData?.frequency} />
          <ViewInfoField
            label="Monthly Shift"
            value={initialData?.monthly_shift}
          />
          <ViewInfoField
            label="Reporting Days"
            value={initialData?.reporting_days}
          />
          <ViewInfoField label="Other Note" value={initialData?.other_notes} />
        </div>
      </div>
    </>
  );
};

export default ReportingRequirement;
