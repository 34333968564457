import { useLocation, useParams } from "react-router-dom";
import MyDropdown from "../../../../../atoms/MyDropdown";
import { useEffect, useState } from "react";
import { getData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import * as XLSX from "xlsx";

const AnalysisView = () => {
  const { date, id } = useParams();
  const [cookies] = useCookies(["t", "roleGroup"]);
  const location = useLocation();
  const { name } = location.state || {};
  const [fileData, setFileData] = useState();

  const getFundingData = async () => {
    try {
      const res = await getData({
        endpoint: "analysis/getByIDandDate",
        token: cookies.t,
        params: {
          id: id,
          date: date,
        },
      });
      setFileData(res?.data);
    } catch (err) {
      console.log("errr", err);
    }
  };
  useEffect(() => {
    if (id && date) {
      getFundingData();
    }
  }, []);

  return (
    <>
      <MyDropdown
        placeholder="Select Sheet"
        // data={clientData}
        onChange={(option) => {
          //   setFieldValue("client_id", option?.value);
          //   setFieldValue("client_name", option?.label);
        }}
        handleBlur={(e) => {
          //   setFieldTouched("client_id", true);
          //   handleBlur(e);
        }}
        // selectedValue={counterData?.client_id}
        format="all"
        menuOpen={true}
        name="client_id"
        // touched={touched.client_id}
        // errors={errors.client_id}
        width="32.426%"
      />
      <ClientFilesTable data={fileData} />
    </>
  );
};

const ClientFilesTable = ({ data }) => {
  const [excelData, setExcelData] = useState({});

  // Function to fetch and parse Excel file
  const fetchAndParseExcel = async (url, category, sheetName) => {
    try {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });

      // Find the sheet and parse it to JSON
      const worksheet = workbook.Sheets[sheetName];
      const jsonSheetData = XLSX.utils.sheet_to_json(worksheet);

      // Store the parsed data
      setExcelData((prevData) => ({
        ...prevData,
        [category]: {
          ...(prevData[category] || {}),
          [sheetName]: jsonSheetData,
        },
      }));
    } catch (error) {
      console.error("Error fetching or parsing Excel file:", error);
    }
  };

  useEffect(() => {
    // Loop through the filesData and fetch the Excel files
    if (data) {
      data.forEach((client) => {
        Object.keys(client.filesData).forEach((category) => {
          client.filesData[category].forEach((fileData) => {
            fileData.sheetsData.forEach((sheet) => {
              fetchAndParseExcel(sheet.data, category, sheet.sheetName);
            });
          });
        });
      });
    }
  }, [data]);

  return (
    <>
      {data && (
        <div>
          {data.map((client, index) => (
            <div key={index}>
              <h2>Client: {client.client_name}</h2>
              <h3>
                Date: {new Date(client.selected_date).toLocaleDateString()}
              </h3>
              <div>
                {Object.keys(client.filesData).map((category, idx) => (
                  <div key={idx}>
                    <h3>{category}</h3>
                    {client.filesData[category].map((fileData, fileIdx) => (
                      <div key={fileIdx}>
                        {fileData.sheetsData.map((sheet, sheetIdx) => (
                          <div key={sheetIdx}>
                            <h4>Sheet: {sheet.sheetName}</h4>
                            <div
                              style={{
                                overflowX: "auto",
                                marginBottom: "20px",
                              }}
                            >
                              {excelData[category] &&
                              excelData[category][sheet.sheetName] ? (
                                <table style={styles.table}>
                                  <thead>
                                    <tr>
                                      {Object.keys(
                                        excelData[category][sheet.sheetName][0],
                                      ).map((key, keyIdx) => (
                                        <th key={keyIdx} style={styles.th}>
                                          {key}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {excelData[category][sheet.sheetName].map(
                                      (row, rowIdx) => (
                                        <tr key={rowIdx}>
                                          {Object.values(row).map(
                                            (value, valueIdx) => (
                                              <td
                                                key={valueIdx}
                                                style={styles.td}
                                              >
                                                {value || "-"}{" "}
                                                {/* Handle empty values */}
                                              </td>
                                            ),
                                          )}
                                        </tr>
                                      ),
                                    )}
                                  </tbody>
                                </table>
                              ) : (
                                <p>Loading sheet data...</p>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

// Styles
const styles = {
  table: {
    width: "100%",
    borderCollapse: "collapse",
    minWidth: "500px", // Ensures a scroll if table overflows
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f4f4f4",
    textAlign: "left",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left", // Optional for alignment consistency
  },
};

export default AnalysisView;
