import PropTypes from "prop-types";
import React from "react";
class Errorboundary extends React.Component {
  state = { hasEror: false };

  static getDerivedStateFromError(error) {
    return { hasEror: true };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
  }

  render() {
    if (this.state.hasEror) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

Errorboundary.propTypes = {
  fallback: PropTypes.node.isRequired, // Ensures fallback is a required node
  children: PropTypes.node.isRequired, // Ensures children is a required node
};
export default Errorboundary;
