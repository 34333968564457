import { useState } from "react";
import CustomMenu from "../CustomMenu";
import CustomButton from "../../atoms/CustomButton";

const ButtonDropDown = ({ menu, buttonTitle }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <CustomButton
        text={buttonTitle}
        type="btn-primary"
        handleClick={(event) => handleStatusMenu(event)}
      />

      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        items={menu}
      />
    </>
  );
};
export default ButtonDropDown;
