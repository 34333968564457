import React, { useEffect, useState } from "react";
import { Heading } from "../../../../../atoms/Heading";
import CustomDatagrid from "../../../../../molecules/CustomDatagrid";
import { getData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import moment from "moment";

const names = {
  Supplier: "supplier_name",
  Client: "client_name",
  Obligor: "obligor_name",
  "Supplier Credit-1": "supplier_name",
  Revolver: "client_name",
};

const formatNumberWithCommas = (number) => {
  return new Intl.NumberFormat().format(number);
};

const Dues = () => {
  const columns = [
    {
      field: "1",
      headerName: "Obligor",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div className={"valueStyle"}>
            {params?.row[names[params?.row?.transaction_type]]}
          </div>
        );
      },
    },
    {
      field: "due_date",
      headerName: "Due Date",
      flex: 0.8,
      renderCell: (params) => {
        const { row } = params;
        const transactionType = row?.transaction_type;
        const createdDate = row?.createdAt; // Assuming createdDate is a valid date string
        const paymentTerms = row?.payment_terms; // Assuming paymentTerms is in a valid format (e.g., days)

        if (
          transactionType === "Supplier" ||
          transactionType === "Supplier Credit-1"
        ) {
          if (createdDate && paymentTerms) {
            const dueDate = moment(createdDate).add(paymentTerms, "days");
            const formattedDueDate = dueDate.format("YYYY-MM-DD");
            return <div className={"valueStyle"}>{formattedDueDate}</div>;
          }
        }

        return <div className={"valueStyle"}>{moment(row?.due_date).format("YYYY-MM-DD") || "N/A"}</div>;
      },
    },

    {
      field: "referance",
      headerName: "Client Ref",
      flex: 0.8,
    },
    {
      field: "funder",
      headerName: "Participant",
      flex: 0.8,
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.8,
    },

    {
      field: "face_value",
      headerName: "Face Value",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div className={"valueStyle"}>
            {formatNumberWithCommas(params.row.face_value)}
          </div>
        );
      },
    },
    {
        field: "cost_value",
        headerName: "Cost",
        flex: 0.8,
        renderCell: (params) => {
          return (
            <div className={"valueStyle"}>
              {formatNumberWithCommas(params.row.cost_value)}
            </div>
          );
        },
    }
  ];
  const [cookies] = useCookies(["t"]);
  const [data, setData] = useState([]);

  const { id } = useParams();

  const getDues = async () => {
    try {
      const res = await getData({
        endpoint: "/funding/funderDues",
        token: cookies.t,
        params: {
          investor_id: id,
        },
      });

      console.log("res", res);

      setData(res?.data?.[0]);
    } catch (error) {}
  };

  useEffect(() => {
    getDues();
  }, []);

  return (
    <div>
      <div className="headingSearchWrap">
        <div>
          <Heading className="HeadingSubStyle">Obligor Assets Due</Heading>
        </div>
      </div>

      <CustomDatagrid
        rows={data?.fundedData}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
      />
    </div>
  );
};

export default Dues;
