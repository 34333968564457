import React from 'react';

const Assign = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.5 10.0002V11.6668M17.5 11.6668V14.1668C17.5 15.0873 16.7538 15.8335 15.8333 15.8335H4.16667C3.24619 15.8335 2.5 15.0873 2.5 14.1668V11.6668M17.5 11.6668H2.5M10 5.00016H4.16667C3.24619 5.00016 2.5 5.74635 2.5 6.66683V11.6668M19.1667 3.3335L15 7.50016L13.3333 5.8335"
        stroke="#1B242C"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Assign;
