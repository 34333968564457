import { Form, Formik, FormikHelpers, Field } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { postData } from '../../../../services';

import toast from 'react-hot-toast';
import {
  confimationStyles,
  errorStyles,
} from '../../../../assets/styles/components/toast';
import Error from '../../../atoms/Error';
import UserIcon from '../../../icons/user.icon';
import TextInput from '../../../atoms/TextInput';

const ForgotPassForm = () => {
  const [pageError, setPageError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const emailLink = (
    <a href="mailto:help@xyz.com" style={{ textDecoration: 'underline' }}>
      help@pxyz.com
    </a>
  );

  const initialValues = {
    email: '',
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required'),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const lowercaseEmail = values.email.toLowerCase();
      const updatedValues = { ...values, email: lowercaseEmail };
      const { data: resultData } = await postData({
        endpoint: 'adminAuth/forgotPassword',
        data: {},
        params: updatedValues,
      });

      if (resultData.status) {
        toast.success(resultData.message, {
          duration: 1000,
          style: confimationStyles,
        });
        navigate('/auth/login');
      } else {
        toast.error(resultData.message, { duration: 1000, style: errorStyles });
        setPageError(
          'This email is not registered with us. Please enter your registered email. If your email has changed, please contact us.',
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-group mb-6">
            <TextInput
              name="email"
              type="text"
              className="primary-input"
              placeholder=""
              customholder="Enter Email"
              autoComplete="off"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/\s/g, '');
              }}
              icon={<UserIcon />}
              InputHeight="large"
            />
          </div>

          <button
            className="btn btn-primary w-100 mt-2 btn-xl mb-4"
            disabled={isSubmitting}
            type="submit"
          >
            Send Link
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm ms-2 text-light"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>

          <button
            className="btn btn-outline-dark w-100 btn-xl"
            type="button"
            onClick={() => navigate('/auth/login')}
          >
            Back to login
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPassForm;
