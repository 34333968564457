import moment from "moment";
import ValueLabelDisplay from "../../../../atoms/ValueLabelDisplay";

const ViewInvestor = ({ data }) => {
  return (
    <>
      <div>
        <ValueLabelDisplay label="Name">
          {data?.investor_name}
        </ValueLabelDisplay>
        <ValueLabelDisplay label="Code">
          {data?.investor_code}
        </ValueLabelDisplay>
        <ValueLabelDisplay label="Address">{data?.address}</ValueLabelDisplay>
        <ValueLabelDisplay label="Master Agreement Date">
          {moment(data?.master_agreement_date).format("YYYY-MM-DD")}
        </ValueLabelDisplay>
        <ValueLabelDisplay label="NAV">{data?.nav}</ValueLabelDisplay>
        <ValueLabelDisplay label="NAV Date">
          {" "}
          {moment(data?.nav_date).format("YYYY-MM-DD")}
        </ValueLabelDisplay>
        <ValueLabelDisplay label={"Interest Party Email"}>
          <div>
            {data?.interest_party_email.map((item, index) => {
              return (
                <div>{`${item} ${
                  data?.interest_party_email.length !== index ? ", " : ""
                }`}</div>
              );
            })}
          </div>
        </ValueLabelDisplay>
      </div>
    </>
  );
};
export default ViewInvestor;
