import { FC } from "react";
import styles from "./form_heading.module.scss";

const Heading = ({ title, subtitle, description, type, className }) => {
  return (
    <div className={`mb-4 ${className}`}>
      <div
        className={` ${type === "center" ? "justify-content-center" : ""} ${
          styles.heading
        }`}
      >
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
      <div
        className={`${styles.description} mt-1 ${
          type === "center" ? "text-center px-1" : ""
        }`}
      >
        {description}
      </div>
    </div>
  );
};

export default Heading;
