const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.6667 10.6667H13.3333V5.33333C13.3333 4.97971 13.1929 4.64057 12.9428 4.39052C12.6928 4.14048 12.3536 4 12 4C11.6464 4 11.3072 4.14048 11.0572 4.39052C10.8071 4.64057 10.6667 4.97971 10.6667 5.33333V10.6667H5.33333C4.97971 10.6667 4.64057 10.8071 4.39052 11.0572C4.14048 11.3072 4 11.6464 4 12C4 12.3536 4.14048 12.6928 4.39052 12.9428C4.64057 13.1929 4.97971 13.3333 5.33333 13.3333H10.6667V18.6667C10.6667 19.0203 10.8071 19.3594 11.0572 19.6095C11.3072 19.8595 11.6464 20 12 20C12.3536 20 12.6928 19.8595 12.9428 19.6095C13.1929 19.3594 13.3333 19.0203 13.3333 18.6667V13.3333H18.6667C19.0203 13.3333 19.3594 13.1929 19.6095 12.9428C19.8595 12.6928 20 12.3536 20 12C20 11.6464 19.8595 11.3072 19.6095 11.0572C19.3594 10.8071 19.0203 10.6667 18.6667 10.6667Z"
        fill="#146ef5"
      />
    </svg>
  );
};
export default PlusIcon;
