import React from "react";
import "./_custom_checkbox.scss";
// a wrapper class for a regular HTML checkbox
function CheckboxWrapper(props) {
  return (
    <label className="check-container">
      <input type="checkbox" {...props} className="checkbox-input" />
      <span className="checkmark"></span>
    </label>
  );
}

export default CheckboxWrapper;
